import {
  Box,
  IconButton,
  Modal,
  Backdrop,
  Typography,
  Stack,
} from "@mui/material";
import React from "react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

const ViewImage = ({
  viewPageValues,
  openModal,
  photoIndex,
  handleCloseModal,
}) => {
  const [currentEnabled, setCurrentEnabled] = React.useState([]);
  const changeIndex = (newIndex) => {
    photoIndex = newIndex;
  };
  function getIndex(item) {
    return viewPageValues.Photos.findIndex((obj) => obj === item);
  }
  if (viewPageValues.Photos != undefined) {
    viewPageValues.Photos.map((item) => {
      if (getIndex(item) === photoIndex) console.log("true " + getIndex(item));
    });
  }
  return (
    <div>
      {/* <Typography
        color="white"
        display={openModal ? "-moz-initial" : "none"}
        position="absolute"
      >
        {"1/10"}
      </Typography> */}
      <Stack direction="row">
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflowX: "scroll",
          }}
          open={openModal}
          onClose={() => {
            handleCloseModal();
          }}
        >
          {viewPageValues.Photos != undefined && (
            <img
              style={{ maxHeight: "80%" }}
              src={`${viewPageValues.Photos[photoIndex].url}`}
              srcSet={`${viewPageValues.Photos[photoIndex].url}`}
              loading="lazy"
              key={viewPageValues.Photos[photoIndex].key}
            />
          )}
        </Modal>
      </Stack>
    </div>
  );
};

export default ViewImage;
