import React from "react";
import { Box, Button, Typography, TextField, Container } from "@mui/material";
import BoxInput from "./BoxInput";
import { createTheme, ThemeProvider, Stack } from "@mui/material";
import { useState } from "react";

const theme = createTheme({
  spacing: 4,
});

const ClientsHeader = ({
  onAddItem,
  queryClients,
  regionSearchTerms,
  admin,
}) => {
  const [rooms, setRooms] = useState("");
  const [regions, setRegions] = useState("");
  const [costFrom, setCostFrom] = useState("");
  const [costTo, setCostTo] = useState("");

  const getTypes = () => {
    console.log(
      `rooms type: ${typeof rooms} ${rooms}\nregions type: ${typeof regions} ${regions}\ncostFrom type: ${typeof costFrom} ${costFrom}\ncostTo type: ${typeof costTo} ${costTo}`
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <div
        className="custom_scrollbar"
        style={{
          overflowX: "scroll",
        }}
      >
        <Box
          id="box-header"
          minWidth="100%"
          display="inline-flex"
          sx={{
            border: 1,
            borderColor: "#b8b8b8",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Box p={2}>
            {admin && (
              <Button
                variant="outlined"
                onClick={onAddItem}
                sx={{ height: "40px" }}
              >
                <Typography variant="body2" fontSize={12} display="inline">
                  Додати клієнта
                </Typography>
              </Button>
            )}
          </Box>
          <Box
            width="100%"
            sx={{
              alignItems: "center",
              alignContent: "right",
              justifyContent: "flex-end",
            }}
            display="inline-flex"
          >
            <Stack spacing={2} direction="row" alignItems="center">
              <Typography
                variant="body2"
                component="body2"
                // marginLeft={2}
                // marginRight={2}
              >
                К-сть кімнат
              </Typography>
              <BoxInput
                inputWidth="40px"
                items={["1", "2", "3"]}
                type="text"
                onChange={(newValue) => {
                  setRooms(newValue);
                }}
              />
              <Typography variant="body2" component="body2">
                Район
              </Typography>
              <BoxInput
                items={regionSearchTerms}
                type="text"
                onChange={(newValue) => {
                  setRegions(newValue);
                }}
              />{" "}
              <Typography variant="body2" component="body2">
                Від
              </Typography>
              <BoxInput
                inputWidth="75px"
                items={["6000"]}
                type="number"
                onChange={(newValue) => {
                  setCostFrom(newValue);
                }}
              />
              <Typography variant="body2" component="body2">
                До
              </Typography>
              <BoxInput
                inputWidth="75px"
                items={["12000"]}
                type="number"
                onChange={(newValue) => {
                  setCostTo(newValue);
                }}
              />
              <Box p={2}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    getTypes();
                    queryClients(false, null, rooms, regions, costFrom, costTo);
                  }}
                >
                  <Typography
                    variant="body2"
                    component="body2"
                    fontStyle="normal"
                  >
                    Знайти
                  </Typography>
                </Button>
              </Box>
            </Stack>
          </Box>
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default ClientsHeader;
