import { Dialog } from "@material-ui/core";
import React from "react";
import { useState, useRef, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Button, Autocomplete, IconButton } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography, Alert } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Collections, AddRounded, Close } from "@mui/icons-material";
import BasicAlerts from "./MyAlert";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const AddRealEstateObject = ({
  onAddItem,
  onCloseDialog,
  regionSearchTerms,
  streetSearchTerms,
}) => {
  const Input = styled("input")({
    display: "none",
  });

  const handleFileInput = (e) => {
    var selectedFiles = [];

    if (!e.target.files || e.target.files.length === 0) {
      return;
    } else {
      for (var i = 0; i < e.target.files.length; i++) {
        selectedFiles.push(e.target.files[i]);
      }
      setInputFiles([...inputFiles, ...selectedFiles]);
    }
  };

  const removeFile = (fileVar) => {
    setInputFiles(inputFiles.filter((file) => file != fileVar));
  };

  const inputFile = useRef(null);
  const [inputFiles, setInputFiles] = useState([]);
  const [personName, setPersonName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dateFrom, setDateFrom] = useState(
    `${new Date().getUTCMonth() +
      1}/${new Date().getUTCDate()}/${new Date().getFullYear()}`
  );
  const [rooms, setRooms] = useState(1);
  const [cost, setCost] = useState(6600);
  const [currency, setCurrency] = useState("");
  const [street, setStreet] = useState("");
  const [region, setRegion] = useState("");
  const [floor, setFloor] = useState(1);
  const [state, setState] = useState("");
  const [heating, setHeating] = useState("");
  const [info, setInfo] = useState("");
  const [furniture, setFurniture] = useState(false);
  const [technology, setTechnology] = useState(false);
  const [displayDateFrom, setDisplayDateFrom] = useState(new Date());

  // const [preview, setPreview] = useState();

  //create a preview as a side effect, whenever selected file is changed
  // useEffect(() => {
  //   if (!inputFiles) {
  //     return;
  //   }

  //   console.log(`inputFiles: ${inputFiles}`);

  //   inputFiles.map((file) => {
  //     console.log(URL.createObjectURL(file));
  //     setImageSamples([...imageSamples, URL.createObjectURL(file)]);
  //   });

  //   // free memory when ever this component is unmounted
  //   return;
  // }, [inputFiles]);

  const onSave = (e) => {
    e.preventDefault();
    onAddItem(
      {
        id: `${Math.floor(Math.random() * 1000000) +
          1}-${new Date().getTime()}`,
        personName: personName,
        phoneNumber: phoneNumber,
        dateFrom: new Date(dateFrom).getTime(),
        rooms: rooms,
        cost: cost,
        currency: currency,
        street: street,
        region: region,
        floor: floor,
        state: state,
        heating: heating,
        info: info,
        furniture: furniture,
        technology: technology,
        dateEdit: new Date(
          `${new Date().getUTCMonth() +
            1}/${new Date().getUTCDate()}/${new Date().getFullYear()}`
        ).getTime(),
      },
      inputFiles
    );
  };

  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <TextField
          id="name"
          label="Імя власника"
          variant="filled"
          type="text"
          value={personName}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          onChange={(e) => setPersonName(e.target.value)}
          fullWidth={true}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <TextField
          id="phone"
          label="Номер телефону власника"
          variant="filled"
          type="text"
          value={phoneNumber}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          onChange={(e) => setPhoneNumber(e.target.value)}
          fullWidth={true}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={displayDateFrom}
            onChange={(newValue) => {
              setDisplayDateFrom(newValue);
              if (newValue === null) setDateFrom("");
              else
                setDateFrom(
                  `${newValue.getUTCMonth() + 1}/${newValue.getUTCDate() +
                    1}/${newValue.getFullYear()}`
                );
            }}
            placeholder="день/місяць/рік"
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "місяць/день/рік",
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") e.preventDefault();
                }}
                fullWidth={true}
                variant="filled"
                label="Дата заповнення"
              />
            )}
          />
        </LocalizationProvider>
      </Box>
      {/* <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={displayDateTo}
            onChange={(newValue) => {
              setDisplayDateTo(newValue);
              if (newValue === null) setDateTo("");
              else
                setDateTo(
                  `${newValue.getUTCMonth() + 1}/${
                    newValue.getUTCDate() + 1
                  }/${newValue.getFullYear()}`
                );
            }}
            placeholder="день/місяць/рік"
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "місяць/день/рік",
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") e.preventDefault();
                }}
                fullWidth={true}
                variant="filled"
                label="Дата кінцевого терміну здачі"
              />
            )}
          />
        </LocalizationProvider>
      </Box> */}
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <TextField
          id="filled-basic"
          label="Кількість кімнат"
          variant="filled"
          type="number"
          value={rooms}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          onChange={(e) => {
            setRooms(e.target.value);
          }}
          fullWidth={true}
          error={rooms === ""}
          helperText={rooms === "" ? "Важливе поле" : " "}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <TextField
          id="filled-basic"
          label="Ціна"
          variant="filled"
          type="number"
          value={cost}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          onChange={(e) => setCost(e.target.value)}
          fullWidth={true}
          error={cost === ""}
          helperText={cost === "" ? "Важливе поле" : " "}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <Autocomplete
          freeSolo
          options={["грн", "$"]}
          onInputChange={(e, newValue) => {
            setCurrency(newValue);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          fullWidth={true}
          renderInput={(params) => (
            <TextField
              {...params}
              type="text"
              variant="filled"
              label="Валюта"
            />
          )}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <Autocomplete
          freeSolo
          options={streetSearchTerms.map((option) => option)}
          onInputChange={(e, newValue) => {
            setStreet(newValue);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          fullWidth={true}
          renderInput={(params) => (
            <TextField
              {...params}
              type="text"
              variant="filled"
              error={street === ""}
              helperText={street === "" ? "Важливе поле" : " "}
              label="Вулиця"
            />
          )}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <Autocomplete
          freeSolo
          options={regionSearchTerms.map((option) => option)}
          onInputChange={(e, newValue) => {
            setRegion(newValue);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          fullWidth={true}
          renderInput={(params) => (
            <TextField
              {...params}
              type="text"
              variant="filled"
              error={region === ""}
              helperText={region === "" ? "Важливе поле" : " "}
              label="Район"
            />
          )}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <TextField
          id="filled-basic"
          label="Поверх"
          variant="filled"
          type="number"
          value={floor}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          onChange={(e) => setFloor(e.target.value)}
          fullWidth={true}
          error={floor === ""}
          helperText={floor === "" ? "Важливе поле" : " "}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <Autocomplete
          freeSolo
          options={["Санітарний", "Під Євро", "Євро"]}
          onInputChange={(e, newValue) => {
            setState(newValue);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          fullWidth={true}
          renderInput={(params) => (
            <TextField {...params} type="text" variant="filled" label="Стан" />
          )}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <Autocomplete
          freeSolo
          options={["Центральне", "Індивідуальне"]}
          onInputChange={(e, newValue) => {
            setHeating(newValue);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          fullWidth={true}
          renderInput={(params) => (
            <TextField
              {...params}
              type="text"
              variant="filled"
              error={heating === ""}
              helperText={heating === "" ? "Важливе поле" : " "}
              label="Опалення"
            />
          )}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <TextField
          id="filled-basic"
          placeholder="Ставте посилання в [ ]"
          label="Додаткова інформація"
          variant="filled"
          type="text"
          value={info}
          onChange={(e) => setInfo(e.target.value)}
          fullWidth={true}
          multiline
        />
      </Box>
      {}
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="left"
        marginTop={1}
        marginLeft={1}
      >
        <FormControlLabel
          control={<Checkbox checked={furniture} />}
          label="Меблі"
          onChange={(e) => setFurniture(e.currentTarget.checked)}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="left"
        marginLeft={1}
        marginBottom={1}
      >
        <FormControlLabel
          control={<Checkbox checked={technology} />}
          label="Технологія"
          onChange={(e) => setTechnology(e.currentTarget.checked)}
        />
      </Box>
      <Stack
        id="uploading-photos"
        direction="row"
        // border={1}
        // borderColor="#85858540"
        marginBottom={2}
        paddingLeft={1}
        spacing={2}
        alignItems="center"
      >
        <Typography>Додати фото:</Typography>
      </Stack>
      <Input
        multiple
        accept="image/*"
        id="contained-button-file"
        type="file"
        ref={inputFile}
        onChange={(e) => {
          handleFileInput(e);
        }}
      />
      <Box
        display="flex"
        justifyContent="flex-start"
        paddingLeft={1}
        paddingBottom={2}
      >
        <Button
          variant="contained"
          endIcon={<Collections />}
          style={{ backgroundColor: "#039dfc", maxHeight: 35 }}
          //color="primary"
          onClick={(e) => inputFile.current && inputFile.current.click()}
        >
          <Typography component="body2" fontWeight="Light">
            Додати
          </Typography>
        </Button>
        <Stack
          paddingLeft={2}
          display="flex"
          style={{ alignItems: "flex-start" }}
        >
          {inputFiles.map((file) => (
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography
                id="test_typhography"
                component="body2"
                fontWeight="Light"
              >
                {file.name}
              </Typography>
              <IconButton
                onClick={() => {
                  removeFile(file);
                }}
              >
                <Close />
              </IconButton>
            </Stack>
          ))}
        </Stack>
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <Button variant="outlined" onClick={onCloseDialog} sx={{ width: 100 }}>
          <Typography variant="body2" fontSize={12} display="inline">
            Закрити
          </Typography>
        </Button>
        <Button
          variant="outlined"
          onClick={(e) => {
            if (
              street !== "" &&
              rooms !== "" &&
              region !== "" &&
              heating !== "" &&
              floor !== "" &&
              cost !== ""
            ) {
              onSave(e);
            } else {
              // <BasicAlerts
              //   severity={"error"}
              //   text={"Будь ласка, заповніть важливі поля"}
              // />;
            }
          }}
          sx={{ width: 100, marginLeft: 2 }}
        >
          <Typography variant="body2" fontSize={12} display="inline">
            Додати
          </Typography>
        </Button>
      </Box>
    </Dialog>
  );
};

export default AddRealEstateObject;
