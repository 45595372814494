import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import BarTabs from "./BarTabs";
import Box from "@mui/material/Box";
import { Keyboard } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import Logo1 from "../logo1_ai copy436_alpha.png";
import { Link, Navigate } from "react-router-dom";
import { Button } from "@mui/material";

const ResponsiveAppBar = ({
  listObjects,
  listClients,
  listArchives,
  admin,
}) => {
  return (
    <Box display="flex" width="100%">
      <AppBar
        position="static"
        color="transparent"
        sx={{
          backdropFilter: "blur(20px)",
        }}
      >
        <Stack direction="row" alignItems="flex-end" spacing={1}>
          <img
            src={Logo1}
            style={{
              maxHeight: 50,
              paddingLeft: 10,
            }}
          />
          <Box paddingLeft={2}>
            <BarTabs
              listObjects={listObjects}
              listClients={listClients}
              listArchives={listArchives}
              admin={admin}
            />
          </Box>
        </Stack>
      </AppBar>
      <Button
        position="absolute"
        variant="outlined"
        style={{ height: 40, width: 150, right: "75px", top: "5px" }}
        onClick={() => window.open("https://leocity.estate", "_self")}
      >
        <Typography fontWeight={300} fontSize={12}>
          Вийти з системи
        </Typography>
      </Button>
    </Box>
  );
};
export default ResponsiveAppBar;
