import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#FFFFFF",
  p: 4,
  overflowY: "scroll",
  height: 500,
};

const BuildingInfo = ({ infoText, openInfo, handleCloseInfo }) => {
  return (
    <Modal
      open={openInfo}
      onClose={() => {
        console.log("clicked close");
        handleCloseInfo();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box m="auto" justifyContent="center" display="flex">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Додаткова інформація
          </Typography>
        </Box>
        <Box m="auto" justifyContent="center" display="flex">
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {infoText}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default BuildingInfo;

BuildingInfo.defaultProps = {
  infoText: "Немає додаткових даних",
};
