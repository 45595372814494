import React from "react";
import EditClient from "../components/EditClient";
import ClientsHeader from "../components/ClientsHeader";
import RealEstateObjects from "../components/RealEstateObjects";
import AddClient from "../dialogs/AddClient";
import LoadingDialog from "../components/LoadingDialog";
import Clients from "../components/Clients";

const ClientsPage = ({
  addItem,
  onAddItem,
  showLoading,
  showAddClient,
  clientItems,
  deleteItem,
  onCloseDialog,
  handleOpenInfo,
  handleEditItem,
  handleDeleteItem,
  queryClients,
  regionSearchTerms,
  isEditItem,
  onCloseEditDialog,
  item,
  loadNextClients,
  admin,
}) => {
  return (
    <div>
      <ClientsHeader
        onAddItem={onAddItem}
        queryClients={queryClients}
        regionSearchTerms={regionSearchTerms}
        admin={admin}
      />
      {showLoading && <LoadingDialog />}
      {showAddClient && (
        <AddClient
          onAddItem={addItem}
          onCloseDialog={onCloseDialog}
          regionSearchTerms={regionSearchTerms}
        />
      )}
      {isEditItem && (
        <EditClient
          onEditItem={addItem}
          onCloseDialog={onCloseEditDialog}
          regionSearchTerms={regionSearchTerms}
          item={item}
        />
      )}
      {clientItems.length > 0 ? (
        <Clients
          items={clientItems}
          onDeleteItem={deleteItem}
          handleOpenInfo={handleOpenInfo}
          handleEditItem={handleEditItem}
          handleDeleteItem={handleDeleteItem}
          loadNextClients={loadNextClients}
          admin={admin}
        />
      ) : (
        <div
          id="No_clients"
          style={{
            alignContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          Наразі немає клієнтів
        </div>
      )}
    </div>
  );
};

export default ClientsPage;
