import * as React from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, createTheme, ThemeProvider } from "@mui/material";
import PropTypes from "prop-types";
import { Keyboard } from "@mui/icons-material";

const theme = createTheme({
  typography: {
    body1: {
      fontSize: 12,
    },
  },
});

export default function BoxInput({ inputWidth, items, type, onChange }) {
  const [inputValue, setInputValue] = React.useState("");
  return (
    <ThemeProvider theme={theme}>
      <Box component="form">
        <Autocomplete
          sx={{
            width: inputWidth,
            "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
              padding: "1px",
            },
            "& .MuiOutlinedInput-root": {},
          }}
          id="free-solo-demo"
          freeSolo
          options={items.map((option) => option)}
          onInputChange={(e, newValue) => {
            onChange(newValue);
            console.log(newValue);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          renderInput={(params) => <TextField {...params} type={type} />}
        />
      </Box>
    </ThemeProvider>
  );
}

BoxInput.defaultProps = {
  inputWidth: "150px",
};
