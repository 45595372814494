import * as React from "react";
import { DataGrid, ukUA } from "@mui/x-data-grid";
import { useEffect } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  Info,
  PhotoCamera,
  Delete,
  Edit,
  Details,
  Visibility,
  VisibilityOff,
  ArrowBack,
  Share,
} from "@mui/icons-material";
import { Container, Stack, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material";
import { IconButton } from "@mui/material";
import "../styles.css";
import { style } from "@mui/system";
import { isMobile } from "react-device-detect";

function stringToDate(_date, _format, _delimiter) {
  try {
    var formatLowerCase = _format.toLowerCase();
    var formatItems = formatLowerCase.split(_delimiter);
    var dateItems = _date.split(_delimiter);
    var monthIndex = formatItems.indexOf("mm");
    var dayIndex = formatItems.indexOf("dd");
    var yearIndex = formatItems.indexOf("yyyy");
    var month = parseInt(dateItems[monthIndex]);
    month -= 1;
    var formatedDate = new Date(
      dateItems[yearIndex],
      month,
      dateItems[dayIndex]
    );
  } catch (err) {
    console.log(err);
    return;
  }
  return formatedDate;
}

export default function ArchivesTable({
  items,
  openArchiveInfo,
  handleOpenPhotos,
  deleteItem,
  loadNextObjects,
  returnItem,
  openViewPage,
  handleEditItem,
}) {
  const MAX_ROW_LENGTH = 50;
  const [loadedRows, setLoadedRows] = React.useState([]);
  const [visible, setVisible] = React.useState(""); //holds row id's
  var greenRow = false;
  const canonical = document.querySelector("link[rel=canonical]");
  var shareDetails = { url: "", title: "Оренда квартири", text: "" };

  const theme = createTheme(ukUA);

  const handleSharing = async (params) => {
    if (navigator.share) {
      try {
        await navigator.share(shareDetails).then(() => {
          if (!isMobile) openViewPage(params);
          console.log("Shared");
        });
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`);
        openViewPage(params);
      }
    } else {
      // fallback code
      console.log(
        "Web share is currently not supported on this browser. Please provide a callback"
      );
      openViewPage(params);
    }
  };

  const getDate = (time) => {
    var date = new Date();
    date.setTime(time);
    return `${date.getUTCMonth() + 1}/${date.getUTCDate() +
      1}/${date.getFullYear()}`;
  };

  var columns = [
    {
      field: "dateFrom",
      headerName: "Додано",
      width: 90,
      renderCell: (cellValues) => {
        return <>{getDate(cellValues.row.dateFrom)}</>;
      },
    },
    {
      field: "dateDeleted",
      headerName: "Видалення",
      width: 90,
      renderCell: (cellValues) => {
        return <>{getDate(cellValues.row.dateDeleted)}</>;
      },
    },
    {
      field: "dateTerm",
      headerName: "Термін",
      width: 90,
      renderCell: (cellValues) => {
        return <>{getDate(cellValues.row.dateTerm)}</>;
      },
    },
    {
      field: "rooms",
      headerName: "Кімнат",
      width: 60,
      type: "number",
    },
    {
      field: "cost",
      headerName: "Ціна",
      width: 70,
      type: "number",
    },
    { field: "currency", headerName: "Валюта", width: 70 },
    { field: "street", headerName: "Вулиця", width: 110 },
    { field: "region", headerName: "Район", width: 100 },
    {
      field: "floor",
      headerName: "Поверх",
      width: 70,
      type: "number",
    },
    { field: "state", headerName: "Стан", width: 70 },
    { field: "heating", headerName: "Опалення", width: 110 },
    {
      field: "furniture",
      headerName: "Меблі",
      width: 70,
      type: "boolean",
    },
    {
      field: "technology",
      headerName: "Техніка",
      width: 70,
      type: "boolean",
    },
    { field: "personName", headerName: "Ім'я", width: 100 },
    {
      field: "phoneNumber",
      headerName: "Номер",
      width: 120,
      renderCell: (cellValues) => {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            {visible === cellValues.row.id ? (
              <>
                {cellValues.formattedValue}
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    setVisible(""); //remove row id from array
                  }}
                >
                  <VisibilityOff style={{ width: 15, height: 15 }} />
                </IconButton>
              </>
            ) : (
              <>
                {`******${cellValues.formattedValue.substring(
                  cellValues.formattedValue.length - 3,
                  cellValues.formattedValue.length
                )}`}
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    setVisible(cellValues.row.id); //add row id to array of visible numbers
                  }}
                >
                  <Visibility style={{ width: 15, height: 15 }} />
                </IconButton>
              </>
            )}
          </Stack>
        );
      },
    },
    {
      field: "info",
      type: "actions",
      width: 220,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<ArrowBack />}
          label="Return"
          onClick={() => returnItem(params.id)}
        />,
        <GridActionsCellItem
          icon={<Info />}
          onClick={() => {
            openArchiveInfo(params.id);
          }}
          label="Info"
        />,
        <GridActionsCellItem
          icon={<PhotoCamera />}
          label="Photos"
          onClick={() => handleOpenPhotos(params.id)}
        />,
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          onClick={() => handleEditItem(params.id)}
        />,
        <GridActionsCellItem
          icon={<Delete />}
          label="Delete"
          onClick={() => deleteItem(params.id)}
        />,
        <GridActionsCellItem
          icon={<Share />}
          label="Share"
          onClick={async () => {
            if (!isMobile) {
              openViewPage(params);
            } else {
              shareDetails.url = `View/${params.id}`;
              shareDetails.text = `${params.row.info}`;
              await handleSharing(params);
            }
          }}
        />,
      ],
    },
  ];

  const handleRowColor = (params) => {
    try {
      var style =
        new Date().getTime() >= params.row.dateTerm
          ? "green-row"
          : "simple-row";
      return style;
    } catch (err) {
      return;
    }
  };
  console.log(window.innerHeight);
  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          height: window.innerHeight - 102,
          width: "100%",
          flexGrow: 1,
        }}
      >
        <Typography textAlign="block" variant="body2" component="body2">
          <DataGrid
            sx={{
              ".MuiDataGrid-cellContent": {
                overflowWrap: "break-word",
              },
              "& .green-row": {
                bgcolor: "#c2ffc6",
              },
              "& .green-row:hover": {
                bgcolor: "#a1ffa7",
              },
              "& .simple-row": {
                bgcolor: "#FFFFFF",
              },
            }}
            disableSelectionOnClick
            disableColumnFilter={true}
            hideFooterSelectedRowCount
            getRowHeight={() => "auto"}
            getEstimatedRowHeight={() => 25}
            rows={items}
            columns={columns}
            pageSize={MAX_ROW_LENGTH}
            rowsPerPageOptions={[MAX_ROW_LENGTH]}
            showCellRightBorder
            showColumnRightBorder
            getRowClassName={(params) => handleRowColor(params)}
            onPageChange={(pageNumber, details) => {
              if (items.length <= MAX_ROW_LENGTH * (pageNumber + 1)) {
                console.log("last page reached");
                loadNextObjects();
              }
            }}
          />
        </Typography>
      </div>
    </ThemeProvider>
  );
}
