import React from "react";
import ClientsDataTable from "../tables/ClientsDataTable";
import RealEstateItem from "./RealEstateItem";

const Clients = ({
  items,
  handleOpenInfo,
  handleEditItem,
  handleDeleteItem,
  loadNextClients,
  admin,
}) => {
  return (
    <>
      <ClientsDataTable
        items={items}
        handleOpenInfo={handleOpenInfo}
        handleEditItem={handleEditItem}
        handleDeleteItem={handleDeleteItem}
        loadNextClients={loadNextClients}
        admin={admin}
      />
    </>
  );
};

export default Clients;
