import React from "react";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import {
  DeleteItemCommand,
  DynamoDBClient,
  PutItemCommand,
  UpdateItemCommand,
  QueryCommand,
  ScanCommand,
} from "@aws-sdk/client-dynamodb";
import {
  PutObjectCommand,
  DeleteObjectCommand,
  DeleteObjectsCommand,
  ListObjectsCommand,
} from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { useEffect, useState } from "react";
import { HashRouter, Link, Navigate } from "react-router-dom";
import ResponsiveAppBar from "./components/AppBar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RealEstateObjectsPage from "./pages/RealEstateObjectsPage";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import BuildingInfo from "./dialogs/Info";
import StandardImageList from "./dialogs/StandardImageList";
import ClientsPage from "./pages/ClientsPage";
import BasicDatePicker from "./components/DatePicker";
import { MuiThemeProvider, createTheme, Hidden } from "@material-ui/core";
import "./index.css";
import { S3Client } from "@aws-sdk/client-s3";
import ArchivesPage from "./pages/ArchivesPage";
import BeforeDeleteObject from "./dialogs/BeforeDeleteObject";
import BeforeDeleteArchive from "./dialogs/BeforeDeleteArchive";
import BeforeReturnArchive from "./dialogs/BeforeReturnArchive";
import App from "./App";

window.Buffer = window.Buffer || require("buffer").Buffer;

const themeBackground = createTheme({
  palette: {
    background: {
      color: "#e4f0e2",
    },
  },
});

// const USER_POOL_ID = "eu-central-1_O4YAKLH4N";
// const USER_POOL_PROVIDER = "cognito-idp." + REGION + ".amazonaws.com/" + USER_POOL_ID;
const PRODUCTION = !window.location.href.includes("localhost");

const TABLE_NAME_OBJECTS = "objects_v3";
const TABLE_NAME_CLIENTS = "clients_v2";
const TABLE_NAME_ARCHIVES = "archives_v2";
const TABLE_NAME_DELETED_ARCHIVE = "deleted_archives";
const REGION = "eu-central-1";
const IDENTITY_POOL_ID = "eu-central-1:713c28fd-d5fa-4e35-a9de-b89640b12b65";
const OBJECTS_PROJECTION =
  "id, cost, currency, dateFrom, dateTo, floor, furniture, heating, info, personName, phoneNumber, region, rooms, state, street, technology";
const CLIENTS_PROJECTION =
  "id, cost, aboutClient, currency, dateFrom, deadline, info, personName, phoneNumber, region, rooms, urgency";
const SCAN_LIMIT = 100;
const S3_PHOTOS_BUCKET = "realtor-photos";

var ddbClient;
var s3Client;
var idToken;
var admin = null;
var openedWindow;

function signIn(idToken) {
  if (!window.location.href.includes("View")) {
    if (ddbClient != null) {
      return;
    }
    console.log("token: " + idToken);

    var credentials = PRODUCTION
      ? fromCognitoIdentityPool({
          client: new CognitoIdentityClient({ region: REGION }),
          identityPoolId: IDENTITY_POOL_ID,
          logins: {
            "cognito-idp.eu-central-1.amazonaws.com/eu-central-1_O4YAKLH4N": idToken,
          },
        })
      : fromCognitoIdentityPool({
          client: new CognitoIdentityClient({ region: REGION }),
          identityPoolId: IDENTITY_POOL_ID,
        });

    ddbClient = new DynamoDBClient({
      region: REGION,
      credentials: credentials,
    });

    s3Client = new S3Client({
      region: REGION,
      credentials: credentials,
    });
  } else {
  }
}

//  const putItem = async () => {
//     try {
//       const data = await ddbClient.send(new PutItemCommand({
//         TableName: TABLE_NAME,
//         Item:{
//           "id": {S: "test123"}
//         }
//       }));
//       console.error("success");
//       return data;
//     } catch (err) {
//       console.error(err);
//     }
//   };

var lastQueryPropertiesObjects,
  lastQueryPropertiesClients,
  lastQueryPropertiesArchives;
var lastEvaluatedKeyObjects, lastEvaluatedKeyClients, lastEvaluatedKeyArchives;

var clientRegionSearchTerms = [];
var regionSearchTerms = [];
var streetSearchTerms = [];

function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay));
}

function AppRealtor() {
  const [displayAdminTools, setDisplayAdminTools] = useState(false);
  const [activeItemId, setActiveItemId] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openReturnDialog, setOpenReturnDialog] = useState(false);
  const [openDeleteArchiveDialog, setOpenDeleteArchiveDialog] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showAddRealEstateObject, setShowAddRealEstateObject] = useState(false);
  const [realEstateItems, setRealEstateItems] = useState([]);
  const [showAddClients, setShowAddClients] = useState(false);
  const [clientItems, setClientItems] = useState([]);
  const [archiveItems, setArchiveItems] = useState([]);
  const [openInfo, setOpenInfo] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [openPhotos, setOpenPhotos] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [isEditItem, setIsEditItem] = useState(false);
  const [editItemId, setEditItemId] = useState("");
  const [currentPage, setCurrentPage] = useState("Objects");
  const [editItem, setEditItem] = useState({
    id: "",
    personName: "",
    phoneNumber: "",
    rooms: "",
    dateFrom: "",
    dateTo: "",
    cost: "",
    currency: "",
    street: "",
    region: "",
    floor: "",
    state: "",
    heating: "",
    info: "",
    furniture: false,
    technology: false,
  });
  const [editClientItem, setEditClientItem] = useState({
    id: "",
    dateFrom: "",
    rooms: "",
    cost: "",
    currency: "",
    region: "",
    aboutClient: "",
    deadline: "",
    urgency: "",
    personName: "",
    phoneNumber: "",
    info: "",
  });
  const [editArchiveItem, setEditArchiveItem] = useState(null);

  const handleOpenInfo = (infoId) => {
    realEstateItems.map((item) => {
      if (item.id === infoId) setInfoText(item.info);
    });
    setOpenInfo(true);
  };
  const handleOpenClientInfo = (infoId) => {
    clientItems.map((item) => {
      if (item.id === infoId) setInfoText(item.info);
    });
    setOpenInfo(true);
  };

  const openArchiveInfo = (infoId) => {
    archiveItems.map((item) => {
      if (item.id === infoId) setInfoText(item.info);
    });
    setOpenInfo(true);
  };

  const onDeletePhoto = async (photo) => {
    console.log(`Delete: ${photo.key}`);
    // setShowLoading(true);
    try {
      await s3Client.send(
        new DeleteObjectCommand({
          Key: photo.key,
          Bucket: S3_PHOTOS_BUCKET,
        })
      );
      console.log("delete success");
      setPhotos(photos.filter((item) => item.key !== photo.key));
    } catch (err) {
      alert(err);
      console.error(err);
    }
    // setShowLoading(false);
  };

  const handleOpenPhotos = async (photosId) => {
    //...
    //after loaded photos
    const albumPhotosKey = encodeURIComponent(photosId) + "/";
    try {
      var photos = [];

      const data = await s3Client.send(
        new ListObjectsCommand({
          Prefix: albumPhotosKey,
          Bucket: S3_PHOTOS_BUCKET,
        })
      );

      if (data.Contents == null || data.Contents.length < 1) {
        alert("Немає фото.");
        return;
      }
      const href = "https://s3." + REGION + ".amazonaws.com/";
      const bucketUrl = href + S3_PHOTOS_BUCKET + "/";

      data.Contents.map((photo) => {
        //console.log(bucketUrl + encodeURIComponent(photo.Key));
        photos.push({
          url: bucketUrl + encodeURIComponent(photo.Key),
          name: photo.Key.split("/").pop(),
          id: photosId,
          key: photo.Key,
        });
      });

      setPhotos(photos);
      setOpenPhotos(true);
    } catch (err) {
      alert(err);
      console.error(err);
    }
  };

  const getPhotos = async (photosId) => {
    //...
    //after loaded photos
    const albumPhotosKey = encodeURIComponent(photosId) + "/";
    try {
      var photos = [];

      const data = await s3Client.send(
        new ListObjectsCommand({
          Prefix: albumPhotosKey,
          Bucket: S3_PHOTOS_BUCKET,
        })
      );

      const href = "https://s3." + REGION + ".amazonaws.com/";
      const bucketUrl = href + S3_PHOTOS_BUCKET + "/";

      data.Contents.map((photo) => {
        //console.log(bucketUrl + encodeURIComponent(photo.Key));
        photos.push({
          url: bucketUrl + encodeURIComponent(photo.Key),
          name: photo.Key.split("/").pop(),
          id: photosId,
          key: photo.Key,
        });
      });

      return photos;
    } catch (err) {
      alert(err);
      console.error(err);
    }
  };

  const handleEditItem = (id) => {
    setIsEditItem(true);
    setEditItemId(id);
    realEstateItems.map((item) => {
      if (item.id === id) setEditItem(item);
    });
  };
  const handleEditClientItem = (id) => {
    setIsEditItem(true);
    setEditItemId(id);
    clientItems.map((item) => {
      if (item.id === id) setEditClientItem(item);
    });
  };
  const handleEditArchiveItem = (id) => {
    setIsEditItem(true);
    setEditItemId(id);
    archiveItems.map((item) => {
      if (item.id === id) setEditArchiveItem(item);
    });
  };

  const onTablePageChange = (pageNumber, details) => {
    console.log(`page: ${pageNumber}`);
  };

  if (ddbClient == null) {
    if (PRODUCTION) {
      console.log("token null: ", idToken == null);
      if (idToken == null) {
        var url_string = window.location.href.replace("#", "?");
        var url = new URL(url_string);
        idToken = url.searchParams.get("id_token");
      }

      if (idToken != null) {
        signIn(idToken);
      } else {
        if (window.location.href.includes("View")) {
        } else {
          var url =
            "https://auth.leocity.estate/login?client_id=806nes7b338tt5cue0ivet5mg&response_type=token&scope=email+openid+profile&redirect_uri=https://leocity.estate/";
          console.error("token is null! redirect to: ", url);
          window.open(
            "https://auth.leocity.estate/login?client_id=806nes7b338tt5cue0ivet5mg&response_type=token&scope=email+openid+profile&redirect_uri=https://leocity.estate/",
            "_self"
          );
        }
      }
    } else {
      signIn(null);
    }
  }

  //check if admin
  const checkAdmin = async () => {
    if (ddbClient == null) return;

    if (window.location.href.includes("View")) {
      admin = false;
      return;
    }

    if (!PRODUCTION) {
      admin = true;
      listObjects();
      return;
    }

    try {
      await ddbClient.send(
        new DeleteItemCommand({
          TableName: TABLE_NAME_OBJECTS,
          Key: {
            id: { S: "admin" },
          },
        })
      );
      console.log("admin === true");
      admin = true;
      listObjects();
    } catch (err) {
      console.log(err);
      if (err.name === "AccessDeniedException") {
        console.log("admin === false");
        admin = false;
        listObjects();
      } else {
        console.log("unknown exception: ", err);
        window.open(
          "https://auth.leocity.estate/login?client_id=806nes7b338tt5cue0ivet5mg&response_type=token&scope=email+openid+profile&redirect_uri=http://localhost:3000/",
          "_self"
        );
      }
    }
  };

  //old list objects
  // const listObjects = async (lastEvaluatedKey) => {
  //   if (ddbClient == null) {
  //     //alert("db client == null");
  //     return;
  //   }

  //   var loadNext = lastEvaluatedKey != null;

  //   if (loadNext && lastEvaluatedKey.lastEvaluatedKey == null) return;

  //   if (loadNext) console.log("load next: ", lastEvaluatedKey);
  //   else console.log("list objects");

  //   setShowLoading(true);

  //   try {
  //     const data = await ddbClient.send(
  //       new ScanCommand({
  //         TableName: TABLE_NAME_OBJECTS,
  //         Limit: SCAN_LIMIT,
  //         ExclusiveStartKey: loadNext
  //           ? lastEvaluatedKey.lastEvaluatedKey
  //           : null,
  //         // ExclusiveStartKey: { lastEvaluatedKey },
  //         // lastEvaluatedKey != null
  //         //   ? { id: { S: "595186Sun, 12 Jun 2022 10:22:27 GMT" } }
  //         //   : lastEvaluatedKey,
  //         // ProjectionExpression: OBJECTS_PROJECTION,
  //       })
  //     );

  //     lastQueryPropertiesObjects = null;
  //     lastEvaluatedKeyObjects = data.LastEvaluatedKey;

  //     console.log("lastEvaluatedKey: ", lastEvaluatedKeyObjects);

  //     var items = [];
  //     data.Items.forEach(function (element, index, array) {
  //       items.push({
  //         id: element.id.S,
  //         personName: element.personName.S,
  //         phoneNumber: element.phoneNumber.S,
  //         rooms: element.rooms.N,
  //         dateFrom: element.dateFrom.S,
  //         dateEdit: element.dateEdit === undefined ? "" : element.dateEdit.S,
  //         cost: element.cost.N,
  //         currency: element.currency.S,
  //         street: element.street.S,
  //         region: element.region.S,
  //         floor: element.floor.N,
  //         state: element.state.S,
  //         heating: element.heating.S,
  //         info: element.info.S,
  //         furniture: element.furniture.BOOL,
  //         technology: element.technology.BOOL,
  //       });
  //     });

  //     if (loadNext) setRealEstateItems([...realEstateItems, ...items]);
  //     else setRealEstateItems(items);

  //     items.map((item) => {
  //       if (streetSearchTerms.indexOf(item.street) === -1)
  //         streetSearchTerms.push(item.street);
  //       if (regionSearchTerms.indexOf(item.region) === -1)
  //         regionSearchTerms.push(item.region);
  //     });
  //   } catch (err) {
  //     alert(err);
  //     console.error(err);
  //   }
  //   setShowLoading(false);
  // };

  //new list objects
  const listObjects = async (loadNext = false, lastEvaluatedKey, prevItems) => {
    if (ddbClient == null) {
      //alert("db client == null");
      return;
    }
    ///test
    // setShowLoading(true);
    // console.log("scanning...");
    // const data = await ddbClient.send(
    //   new QueryCommand({
    //     TableName: "objects_v2",
    //     IndexName: "global-dateEdit-index",
    //     KeyConditionExpression: "#global = :global",
    //     ExpressionAttributeNames: {
    //       "#global": "global",
    //     },
    //     ExpressionAttributeValues: {
    //       ":global": { N: "0" },
    //     },
    //     Limit: 1000,
    //     ExclusiveStartKey: loadNext ? lastEvaluatedKey : null,
    //     ScanIndexForward: false,
    //   })
    // );
    // console.log("scanned: ", data.Items);
    // console.log("adding...");
    // var tasks = [];
    // data.Items.forEach(function(element, index, array) {
    //   // console.log(new Date(element.dateFrom.S).getTime());
    //   tasks.push(
    //     ddbClient.send(
    //       new PutItemCommand({
    //         TableName: TABLE_NAME_OBJECTS,
    //         Item: {
    //           id: element.id,
    //           global: { N: "0" },
    //           personName: element.personName,
    //           phoneNumber: element.phoneNumber,
    //           dateFrom: { N: `${new Date(element.dateFrom.S).getTime()}` },
    //           dateEdit: { N: `${new Date(element.dateEdit.S).getTime()}` },
    //           rooms: element.rooms,
    //           cost: element.cost,
    //           currency: element.currency,
    //           street: element.street,
    //           region: element.region,
    //           floor: element.floor,
    //           state: element.state,
    //           heating: element.heating,
    //           info: element.info,
    //           furniture: element.furniture,
    //           technology: element.technology,
    //         },
    //       })
    //     )
    //   );
    // });

    // await Promise.all(tasks);
    // console.log("added");

    // console.log("scanning...");
    // const data2 = await ddbClient.send(
    //   new QueryCommand({
    //     TableName: "archives",
    //     IndexName: "global-dateTerm-index",
    //     KeyConditionExpression: "#global = :global",
    //     ExpressionAttributeNames: {
    //       "#global": "global",
    //     },
    //     ExpressionAttributeValues: {
    //       ":global": { N: "0" },
    //     },
    //     Limit: 1000,
    //     ExclusiveStartKey: loadNext ? lastEvaluatedKey : null,
    //   })
    // );
    // console.log("scanned: ", data2.Items);
    // console.log("adding...");
    // var tasks = [];
    // data2.Items.forEach(function(element, index, array) {
    //   tasks.push(
    //     ddbClient.send(
    //       new PutItemCommand({
    //         TableName: TABLE_NAME_ARCHIVES,
    //         Item: {
    //           id: element.id,
    //           global: { N: "0" },
    //           personName: element.personName,
    //           phoneNumber: element.phoneNumber,
    //           dateFrom: { N: `${new Date(element.dateFrom.S).getTime()}` },
    //           dateDeleted: {
    //             N: `${new Date(element.dateDeleted.S).getTime()}`,
    //           },
    //           dateTerm: element.dateTerm,
    //           dateEdit: {
    //             N:
    //               element.dateEdit === undefined
    //                 ? "0"
    //                 : `${new Date(element.dateEdit.S).getTime()}`,
    //           },
    //           rooms: element.rooms,
    //           cost: element.cost,
    //           currency: element.currency,
    //           street: element.street,
    //           region: element.region,
    //           floor: element.floor,
    //           state: element.state,
    //           heating: element.heating,
    //           info: element.info,
    //           furniture: element.furniture,
    //           technology: element.technology,
    //         },
    //       })
    //     )
    //   );
    // });

    // await Promise.all(tasks);
    // console.log("added");
    // setShowLoading(false);
    // return;
    ///
    if (loadNext && lastEvaluatedKey == null) return;

    if (loadNext) console.log("load next: ", lastEvaluatedKey);
    else console.log("list objects");

    console.log("prev: ", prevItems);

    setShowLoading(true);

    try {
      const data = await ddbClient.send(
        new QueryCommand({
          TableName: TABLE_NAME_OBJECTS,
          IndexName: "global-dateEdit-index",
          KeyConditionExpression: "#global = :global",
          ExpressionAttributeNames: {
            "#global": "global",
          },
          ExpressionAttributeValues: {
            ":global": { N: "0" },
          },
          Limit: SCAN_LIMIT,
          ExclusiveStartKey: loadNext ? lastEvaluatedKey : null,
          ScanIndexForward: false,
        })
      );

      lastQueryPropertiesObjects = null;
      lastEvaluatedKeyObjects = data.LastEvaluatedKey;

      console.log("lastEvaluatedKey: ", data.LastEvaluatedKey);

      var items = [];
      // var dateFrom = new Date(),
      //   dateEdit = new Date();
      if (prevItems != null) items = [...prevItems];
      data.Items.forEach(function(element, index, array) {
        // var dateFrom = new Date();
        // var dateEdit = new Date();
        // dateFrom.setTime(element.dateFrom.N);
        // dateFrom = `${dateFrom.getUTCMonth() + 1}/${dateFrom.getUTCDate() +
        //   1}/${dateFrom.getFullYear()}`;

        // dateEdit.setTime(element.dateEdit.N);
        // dateEdit = `${dateEdit.getUTCMonth() + 1}/${dateEdit.getUTCDate() +
        //   1}/${dateEdit.getFullYear()}`;
        items.push({
          id: element.id.S,
          personName: element.personName.S,
          phoneNumber: element.phoneNumber.S,
          rooms: element.rooms.N,
          dateFrom: element.dateFrom.N,
          dateEdit: element.dateEdit.N === undefined ? "0" : element.dateEdit.N,
          cost: element.cost.N,
          currency: element.currency.S,
          street: element.street.S,
          region: element.region.S,
          floor: element.floor.N,
          state: element.state.S,
          heating: element.heating.S,
          info: element.info.S,
          furniture: element.furniture.BOOL,
          technology: element.technology.BOOL,
        });
      });
      setRealEstateItems(items);
      items.map((item) => {
        if (streetSearchTerms.indexOf(item.street) === -1)
          streetSearchTerms.push(item.street);
        if (regionSearchTerms.indexOf(item.region) === -1)
          regionSearchTerms.push(item.region);
      });
    } catch (err) {
      alert(err);
      console.error(err);
    }
    setCurrentPage("Objects");
    setShowLoading(false);
  };

  const filterObjects = async (filterProps) => {
    if (ddbClient == null) {
      //alert("db client == null");
      return;
    }
    /*test
    setShowLoading(true);
    console.log("scanning...");
    const data = await ddbClient.send(
      new QueryCommand({
        TableName: "objects_v2",
        IndexName: "global-dateEdit-index",
        KeyConditionExpression: "#global = :global",
        ExpressionAttributeNames: {
          "#global": "global",
        },
        ExpressionAttributeValues: {
          ":global": { N: "0" },
        },
        Limit: 1000,
        ExclusiveStartKey: loadNext ? lastEvaluatedKey : null,
        ScanIndexForward: false,
      })
    );
    console.log("scanned: ", data.Items);
    console.log("adding...");
    var tasks = [];
    data.Items.forEach(function(element, index, array) {
      // console.log(new Date(element.dateFrom.S).getTime());
      tasks.push(
        ddbClient.send(
          new PutItemCommand({
            TableName: TABLE_NAME_OBJECTS,
            Item: {
              id: element.id,
              global: { N: "0" },
              personName: element.personName,
              phoneNumber: element.phoneNumber,
              dateFrom: { N: `${new Date(element.dateFrom.S).getTime()}` },
              dateEdit: { N: `${new Date(element.dateEdit.S).getTime()}` },
              rooms: element.rooms,
              cost: element.cost,
              currency: element.currency,
              street: element.street,
              region: element.region,
              floor: element.floor,
              state: element.state,
              heating: element.heating,
              info: element.info,
              furniture: element.furniture,
              technology: element.technology,
            },
          })
        )
      );
    });

    await Promise.all(tasks);
    console.log("added");

    console.log("scanning...");
    const data2 = await ddbClient.send(
      new QueryCommand({
        TableName: "archives",
        IndexName: "global-dateTerm-index",
        KeyConditionExpression: "#global = :global",
        ExpressionAttributeNames: {
          "#global": "global",
        },
        ExpressionAttributeValues: {
          ":global": { N: "0" },
        },
        Limit: 1000,
        ExclusiveStartKey: loadNext ? lastEvaluatedKey : null,
      })
    );
    console.log("scanned: ", data2.Items);
    console.log("adding...");
    var tasks = [];
    data2.Items.forEach(function(element, index, array) {
      tasks.push(
        ddbClient.send(
          new PutItemCommand({
            TableName: TABLE_NAME_ARCHIVES,
            Item: {
              id: element.id,
              global: { N: "0" },
              personName: element.personName,
              phoneNumber: element.phoneNumber,
              dateFrom: { N: `${new Date(element.dateFrom.S).getTime()}` },
              dateDeleted: {
                N: `${new Date(element.dateDeleted.S).getTime()}`,
              },
              dateTerm: element.dateTerm,
              dateEdit: {
                N:
                  element.dateEdit === undefined
                    ? "0"
                    : `${new Date(element.dateEdit.S).getTime()}`,
              },
              rooms: element.rooms,
              cost: element.cost,
              currency: element.currency,
              street: element.street,
              region: element.region,
              floor: element.floor,
              state: element.state,
              heating: element.heating,
              info: element.info,
              furniture: element.furniture,
              technology: element.technology,
            },
          })
        )
      );
    });

    await Promise.all(tasks);
    console.log("added");
    setShowLoading(false);
    return;
    */
    //if (loadNext && lastEvaluatedKey == null) return;

    //if (loadNext) console.log("load next: ", lastEvaluatedKey);
    //else console.log("list objects");

    //console.log("prev: ", prevItems);

    setShowLoading(true);

    try {
      const data = await ddbClient.send(
        new QueryCommand({
          TableName: TABLE_NAME_OBJECTS,
          IndexName: "global-dateEdit-index",
          KeyConditionExpression: "#global = :global",
          ExpressionAttributeNames: {
            "#global": "global",
          },
          ExpressionAttributeValues: {
            ":global": { N: "0" },
          },
          Limit: 10000,
          ExclusiveStartKey: null,
          ScanIndexForward: false,
        })
      );

      //console.log("lastEvaluatedKey: ", data.LastEvaluatedKey);

      let items = new Array();
      // var dateFrom = new Date(),
      //   dateEdit = new Date();
      data.Items.forEach(function(element, index, array) {
        // var dateFrom = new Date();
        // var dateEdit = new Date();
        // dateFrom.setTime(element.dateFrom.N);
        // dateFrom = `${dateFrom.getUTCMonth() + 1}/${dateFrom.getUTCDate() +
        //   1}/${dateFrom.getFullYear()}`;

        // dateEdit.setTime(element.dateEdit.N);
        // dateEdit = `${dateEdit.getUTCMonth() + 1}/${dateEdit.getUTCDate() +
        //   1}/${dateEdit.getFullYear()}`;
        // console.log(filterProps.fromCost);
        // console.log(filterProps.toCost == -1);
        if (
          (element.street.S.toLowerCase().includes(
            filterProps.street.toLowerCase()
          ) ||
            filterProps.street.length == 0) &&
          (filterProps.rooms == -1 || filterProps.rooms == element.rooms.N) &&
          (filterProps.region.length == 0 ||
            element.region.S.toLowerCase().includes(
              filterProps.region.toLowerCase()
            )) &&
          (filterProps.heating.length == 0 ||
            element.heating.S.toLowerCase().includes(
              filterProps.heating.toLowerCase()
            )) &&
          (filterProps.fromCost == -1 ||
            element.cost.N >= filterProps.fromCost) &&
          (filterProps.toCost == -1 || element.cost.N <= filterProps.toCost)
        ) {
          items.push({
            id: element.id.S,
            personName: element.personName.S,
            phoneNumber: element.phoneNumber.S,
            rooms: element.rooms.N,
            dateFrom: element.dateFrom.N,
            dateEdit:
              element.dateEdit.N === undefined ? "0" : element.dateEdit.N,
            cost: element.cost.N,
            currency: element.currency.S,
            street: element.street.S,
            region: element.region.S,
            floor: element.floor.N,
            state: element.state.S,
            heating: element.heating.S,
            info: element.info.S,
            furniture: element.furniture.BOOL,
            technology: element.technology.BOOL,
          });
        }
      });
      setRealEstateItems(items);
    } catch (err) {
      alert(err);
      console.error(err);
    }
    setCurrentPage("Objects");
    setShowLoading(false);
  };

  //query objects
  const queryObjects = async (
    loadNext = false,
    lastEvaluatedKey,
    street,
    rooms,
    region,
    heating,
    fromCost,
    toCost,
    prevItems
  ) => {
    setShowLoading(true);
    console.log(`loadNext: ${loadNext}`);
    try {
      if (loadNext && lastEvaluatedKey == null) {
        setShowLoading(false);
        return;
      }

      // if (!loadNext) {
      //   if (
      //     street.length === 0 &&
      //     rooms.length === 0 &&
      //     region.length === 0 &&
      //     heating.length === 0 &&
      //     fromCost.length === 0 &&
      //     toCost.length === 0
      //   ) {
      //     listObjects();
      //     return;
      //   }
      //   var keyConditionExpression = "#index = :index";
      //   var expressionAttributeNames = { "#index": "global" };
      //   var expressionAttributeValues = { ":index": { N: "0" } };
      //   var startingValue = "";
      //   var indexName = "global";

      //   if (street.length !== 0) {
      //     indexName = "street";
      //     expressionAttributeValues[":index"] = { S: `${street}` };
      //     startingValue = `${street}`;
      //   }

      //   if (rooms.length !== 0) {
      //     if (indexName === "global") {
      //       indexName = "rooms";
      //       expressionAttributeValues[":index"] = { N: `${rooms}` };
      //       startingValue = `${rooms}`;
      //     } else {
      //       indexName += "_rooms";
      //       startingValue = `${startingValue}_${rooms}`;
      //       expressionAttributeValues[":index"] = {
      //         S: startingValue,
      //       };
      //     }
      //   }

      //   if (region.length !== 0) {
      //     if (indexName === "global") {
      //       indexName = "region";
      //       expressionAttributeValues[":index"] = { S: `${region}` };
      //       startingValue = `${region}`;
      //     } else {
      //       indexName += "_region";
      //       startingValue = `${startingValue}_${region}`;
      //       expressionAttributeValues[":index"] = {
      //         S: startingValue,
      //       };
      //     }
      //   }

      //   if (heating.length !== 0) {
      //     if (indexName === "global") {
      //       indexName = "heating";
      //       expressionAttributeValues[":index"] = { S: `${heating}` };
      //       startingValue = `${heating}`;
      //     } else {
      //       indexName += "_heating";
      //       startingValue = `${startingValue}_${heating}`;
      //       expressionAttributeValues[":index"] = {
      //         S: startingValue,
      //       };
      //     }
      //   }

      //   if (indexName !== "global") {
      //     expressionAttributeNames["#index"] = indexName;
      //   }

      //   if (fromCost.length !== 0 || toCost.length !== 0) {
      //     expressionAttributeNames["#cost"] = "cost";
      //   }

      //   if (fromCost.length !== 0 && toCost.length !== 0) {
      //     keyConditionExpression += " AND #cost BETWEEN :fromCost AND :toCost";
      //     expressionAttributeValues[":fromCost"] = { N: fromCost };
      //     expressionAttributeValues[":toCost"] = { N: toCost };
      //   } else {
      //     if (fromCost.length !== 0) {
      //       keyConditionExpression += " AND #cost >= :fromCost";
      //       expressionAttributeValues[":fromCost"] = { N: fromCost };
      //     }

      //     if (toCost.length !== 0) {
      //       keyConditionExpression += " AND #cost <= :toCost";
      //       expressionAttributeValues[":toCost"] = { N: toCost };
      //     }
      //   }

      //   indexName += "-cost-index";

      //   console.log(
      //     `query: ${expressionAttributeNames["#index"]} = ${expressionAttributeValues[":index"].S}`
      //   );
      // } else {
      //   console.log("query next: ", lastEvaluatedKey);
      //   indexName = lastQueryPropertiesObjects.IndexName;
      //   keyConditionExpression =
      //     lastQueryPropertiesObjects.KeyConditionExpression;
      //   expressionAttributeValues =
      //     lastQueryPropertiesObjects.ExpressionAttributeValues;
      //   expressionAttributeNames =
      //     lastQueryPropertiesObjects.ExpressionAttributeNames;
      // }

      // var data = await ddbClient.send(
      //   new QueryCommand({
      //     TableName: TABLE_NAME_OBJECTS,
      //     IndexName: indexName,
      //     KeyConditionExpression: keyConditionExpression,
      //     ExpressionAttributeValues: expressionAttributeValues,
      //     ExpressionAttributeNames: expressionAttributeNames,
      //     Limit: SCAN_LIMIT,
      //     ExclusiveStartKey: loadNext
      //       ? lastEvaluatedKey.lastEvaluatedKey
      //       : null,
      //     // ProjectionExpression: OBJECTS_PROJECTION,
      //   })
      // );

      // lastQueryPropertiesObjects = {
      //   IndexName: indexName,
      //   KeyConditionExpression: keyConditionExpression,
      //   ExpressionAttributeValues: expressionAttributeValues,
      //   ExpressionAttributeNames: expressionAttributeNames,
      // };
      // lastEvaluatedKeyObjects = data.LastEvaluatedKey;

      if (!loadNext) {
        if (
          street.length === 0 &&
          rooms.length === 0 &&
          region.length === 0 &&
          heating.length === 0 &&
          fromCost.length === 0 &&
          toCost.length === 0
        ) {
          listObjects();
          return;
        }
        var filterExpression = "";
        var expressionAttributeNames = {
          "#global": "global",
        };
        var expressionAttributeValues = {
          ":global": { N: "0" },
        };

        if (street.length !== 0) {
          filterExpression = "contains(#street, :street)";
          expressionAttributeNames["#street"] = "street";
          expressionAttributeValues[":street"] = {
            S: `${street}`,
          };
        }

        if (rooms.length !== 0) {
          expressionAttributeNames["#rooms"] = "rooms";
          expressionAttributeValues[":rooms"] = {
            N: `${rooms}`,
          };
          if (filterExpression.length < 1) {
            filterExpression = "#rooms = :rooms";
          } else {
            filterExpression += " AND #rooms = :rooms";
          }
        }

        if (region.length !== 0) {
          expressionAttributeNames["#region"] = "region";
          expressionAttributeValues[":region"] = {
            S: region,
          };
          if (filterExpression.length < 1) {
            filterExpression = "contains(#region, :region)";
          } else {
            filterExpression += " AND contains(#region, :region)";
          }
        }

        if (heating.length !== 0) {
          expressionAttributeNames["#heating"] = "heating";
          expressionAttributeValues[":heating"] = {
            S: heating,
          };
          if (filterExpression.length < 1) {
            filterExpression = "contains(#heating, :heating)";
          } else {
            filterExpression += " AND contains(#heating, :heating)";
          }
        }

        if (fromCost.length !== 0 || toCost.length !== 0) {
          expressionAttributeNames["#cost"] = "cost";
        }

        if (fromCost.length !== 0 && toCost.length !== 0) {
          if (filterExpression.length < 1) {
            filterExpression += "#cost BETWEEN :fromCost AND :toCost";
          } else {
            filterExpression += " AND #cost BETWEEN :fromCost AND :toCost";
          }
          expressionAttributeValues[":fromCost"] = {
            N: fromCost,
          };
          expressionAttributeValues[":toCost"] = {
            N: toCost,
          };
        } else {
          if (fromCost.length !== 0) {
            if (filterExpression.length < 1) {
              filterExpression += "#cost >= :fromCost";
            } else {
              filterExpression += " AND #cost >= :fromCost";
            }
            expressionAttributeValues[":fromCost"] = {
              N: fromCost,
            };
          }

          if (toCost.length !== 0) {
            if (filterExpression.length < 1) {
              filterExpression += "#cost <= :toCost";
            } else {
              filterExpression += " AND #cost <= :toCost";
            }
            expressionAttributeValues[":toCost"] = {
              N: toCost,
            };
          }
        }

        console.log(`query filter: ${filterExpression}`);
      } else {
        console.log("query next: ", lastEvaluatedKey);
        console.log(
          `query filter: ${lastQueryPropertiesObjects.FilterExpression}`
        );
        filterExpression = lastQueryPropertiesObjects.FilterExpression;
        expressionAttributeValues =
          lastQueryPropertiesObjects.ExpressionAttributeValues;
        expressionAttributeNames =
          lastQueryPropertiesObjects.ExpressionAttributeNames;
      }

      var data = await ddbClient.send(
        new QueryCommand({
          TableName: TABLE_NAME_OBJECTS,
          IndexName: "global-dateEdit-index",
          KeyConditionExpression: "#global = :global",
          FilterExpression: filterExpression,
          ExpressionAttributeNames: expressionAttributeNames,
          ExpressionAttributeValues: expressionAttributeValues,
          Limit: SCAN_LIMIT,
          ExclusiveStartKey: loadNext ? lastEvaluatedKey : null,
          // ProjectionExpression: OBJECTS_PROJECTION,
        })
      );

      lastQueryPropertiesObjects = {
        FilterExpression: filterExpression,
        ExpressionAttributeValues: expressionAttributeValues,
        ExpressionAttributeNames: expressionAttributeNames,
      };
      lastEvaluatedKeyObjects = data.LastEvaluatedKey;

      console.log("lastKey: ", data.LastEvaluatedKey);

      var items = [];
      if (prevItems != null) items = [...prevItems];
      data.Items.forEach(function(element, index, array) {
        items.push({
          id: element.id.S,
          personName: element.personName.S,
          phoneNumber: element.phoneNumber.S,
          rooms: element.rooms.N,
          dateFrom: element.dateFrom.N,
          dateEdit: element.dateEdit.N,
          // dateTo: element.dateTo.S,
          cost: element.cost.N,
          currency: element.currency.S,
          street: element.street.S,
          region: element.region.S,
          floor: element.floor.N,
          state: element.state.S,
          heating: element.heating.S,
          info: element.info.S,
          photos: "", //element.photos.L
          furniture: element.furniture.BOOL,
          technology: element.technology.BOOL,
        });
      });

      if (items.length < 100 && lastEvaluatedKeyObjects != null) {
        await timeout(200);
        console.log("Waiting 200ms");
        queryObjects(
          true,
          lastEvaluatedKeyObjects,
          null,
          null,
          null,
          null,
          null,
          null,
          items
        );
        return;
      }

      console.log(items);

      setRealEstateItems(items);
    } catch (err) {
      alert(err);
      console.error(err);
    }
    setShowLoading(false);
  };

  //list clients
  const listClients = async (loadNext = false, lastEvaluatedKey, prevItems) => {
    if (ddbClient == null) {
      // alert("db client == null");
      return;
    }

    if (loadNext && lastEvaluatedKey == null) return;

    if (loadNext) console.log("load next: ", lastEvaluatedKey);
    else console.log("list clients");

    setShowLoading(true);

    try {
      const data = await ddbClient.send(
        new QueryCommand({
          TableName: TABLE_NAME_CLIENTS,
          Limit: SCAN_LIMIT,
          ExclusiveStartKey: loadNext ? lastEvaluatedKey : null,
          IndexName: "global-dateEdit-index",
          KeyConditionExpression: "#global = :global",
          ExpressionAttributeNames: {
            "#global": "global",
          },
          ExpressionAttributeValues: {
            ":global": { N: "0" },
          },
          ScanIndexForward: false,
          // ProjectionExpression: CLIENTS_PROJECTION,
        })
      );

      lastQueryPropertiesClients = null;
      lastEvaluatedKeyClients = data.LastEvaluatedKey;

      var items = [];
      if (prevItems != null) items = [...prevItems];
      data.Items.forEach(function(element, index, array) {
        items.push({
          id: element.id.S,
          dateFrom: element.dateFrom.S,
          dateEdit:
            element.dateEdit === undefined
              ? new Date(element.dateFrom.S).getTime()
              : element.dateEdit.N,
          rooms: element.rooms.N,
          cost: element.cost.N,
          currency: element.currency.S,
          region: element.region.S,
          aboutClient: element.aboutClient.S,
          deadline: element.deadline.N,
          urgency: element.urgency.S,
          personName: element.personName.S,
          phoneNumber: element.phoneNumber.S,
          info: element.info.S,
        });
      });
      setClientItems(items);

      items.map((item) => {
        if (clientRegionSearchTerms.indexOf(item.region) === -1) {
          clientRegionSearchTerms.push(item.region);
        }
      });
    } catch (err) {
      alert(err);
      console.error(err);
    }
    setCurrentPage("Clients");
    setShowLoading(false);
  };

  //query clients
  const queryClients = async (
    loadNext,
    lastEvaluatedKey,
    rooms,
    region,
    fromCost,
    toCost,
    prevItems
  ) => {
    console.log(`${rooms} ${region} ${fromCost} ${toCost}`);
    setShowLoading(true);

    try {
      if (loadNext && lastEvaluatedKey == null) {
        setShowLoading(false);
        return;
      }

      if (!loadNext) {
        if (
          rooms.length === 0 &&
          region.length === 0 &&
          fromCost.length === 0 &&
          toCost.length === 0
        ) {
          listClients();
          return;
        }
        var keyConditionExpression = "#index = :index";
        var expressionAttributeNames = {
          "#index": "global",
        };
        var expressionAttributeValues = {
          ":index": { N: "0" },
        };
        var startingValue = "";
        var indexName = "global";

        if (rooms.length !== 0) {
          indexName = "rooms";
          expressionAttributeValues[":index"] = {
            N: `${rooms}`,
          };
          startingValue = `${rooms}`;
        }

        if (region.length !== 0) {
          if (indexName === "global") {
            indexName = "region";
            expressionAttributeValues[":index"] = {
              S: `${region}`,
            };
            startingValue = `${region}`;
          } else {
            indexName += "_region";
            startingValue = `${startingValue}_${region}`;
            expressionAttributeValues[":index"] = {
              S: startingValue,
            };
          }
        }

        if (indexName !== "global") {
          expressionAttributeNames["#index"] = indexName;
        }

        if (fromCost.length !== 0 || toCost.length !== 0) {
          expressionAttributeNames["#cost"] = "cost";
        }

        if (fromCost.length !== 0 && toCost.length !== 0) {
          keyConditionExpression += " AND #cost BETWEEN :fromCost AND :toCost";
          expressionAttributeValues[":fromCost"] = {
            N: fromCost,
          };
          expressionAttributeValues[":toCost"] = {
            N: toCost,
          };
        } else {
          if (fromCost.length !== 0) {
            keyConditionExpression += " AND #cost >= :fromCost";
            expressionAttributeValues[":fromCost"] = {
              N: fromCost,
            };
          }

          if (toCost.length !== 0) {
            keyConditionExpression += " AND #cost <= :toCost";
            expressionAttributeValues[":toCost"] = {
              N: toCost,
            };
          }
        }

        indexName += "-cost-index";

        console.log(toCost);
      } else {
        console.log("query next: ", lastEvaluatedKey);
        indexName = lastQueryPropertiesClients.IndexName;
        keyConditionExpression =
          lastQueryPropertiesClients.KeyConditionExpression;
        expressionAttributeValues =
          lastQueryPropertiesClients.ExpressionAttributeValues;
        expressionAttributeNames =
          lastQueryPropertiesClients.ExpressionAttributeNames;
      }

      var data = await ddbClient.send(
        new QueryCommand({
          TableName: TABLE_NAME_CLIENTS,
          IndexName: indexName,
          KeyConditionExpression: keyConditionExpression,
          ExpressionAttributeValues: expressionAttributeValues,
          ExpressionAttributeNames: expressionAttributeNames,
          Limit: SCAN_LIMIT,
          ExclusiveStartKey: loadNext ? lastEvaluatedKey : null,
          // ProjectionExpression: CLIENTS_PROJECTION,
        })
      );

      lastQueryPropertiesClients = {
        IndexName: indexName,
        KeyConditionExpression: keyConditionExpression,
        ExpressionAttributeValues: expressionAttributeValues,
        ExpressionAttributeNames: expressionAttributeNames,
      };
      lastEvaluatedKeyClients = data.LastEvaluatedKey;

      var items = [];
      if (prevItems != null) items = [...prevItems];
      data.Items.forEach(function(element, index, array) {
        items.push({
          id: element.id.S,
          dateFrom: element.dateFrom.S,
          dateEdit: element.dateEdit.N,
          rooms: element.rooms.N,
          cost: element.cost.N,
          currency: element.currency.S,
          region: element.region.S,
          aboutClient: element.aboutClient.S,
          deadline: element.deadline.N,
          urgency: element.urgency.S,
          personName: element.personName.S,
          phoneNumber: element.phoneNumber.S,
          info: element.info.S,
        });
      });
      setClientItems(items);
    } catch (err) {
      alert(err);
      console.error(err);
    }
    setShowLoading(false);
  };

  //list archives
  const listArchives = async (
    loadNext = false,
    lastEvaluatedKey,
    prevItems
  ) => {
    if (ddbClient == null) {
      // alert("db client == null");
      return;
    }

    if (loadNext && lastEvaluatedKey == null) {
      console.log(`loadNext: ${loadNext}`);
      console.log(lastEvaluatedKey);
      console.log(prevItems);
      return;
    }

    if (loadNext) console.log("load next: ", lastEvaluatedKey);
    else console.log("list archives");

    setShowLoading(true);

    try {
      const data = await ddbClient.send(
        new QueryCommand({
          TableName: TABLE_NAME_ARCHIVES,
          IndexName: "global-dateTerm-index",
          KeyConditionExpression: "#global = :global",
          ExpressionAttributeNames: {
            "#global": "global",
          },
          ExpressionAttributeValues: {
            ":global": { N: "0" },
          },
          Limit: SCAN_LIMIT,
          ExclusiveStartKey: loadNext ? lastEvaluatedKey : null,
        })
      );

      lastQueryPropertiesArchives = null;
      lastEvaluatedKeyArchives = data.LastEvaluatedKey;

      console.log("lastEvaluatedKey: ", data.LastEvaluatedKey);

      var items = [];
      if (prevItems != null) items = [...prevItems];
      data.Items.forEach(function(element, index, array) {
        // var date = new Date();
        // date.setTime(element.dateTerm.N);
        // date = `${date.getUTCMonth() + 1}/${date.getUTCDate() +
        //   1}/${date.getFullYear()}`;
        items.push({
          id: element.id.S,
          personName: element.personName.S,
          phoneNumber: element.phoneNumber.S,
          rooms: element.rooms.N,
          dateFrom: element.dateFrom.N,
          dateDeleted: element.dateDeleted.N,
          dateTerm: element.dateTerm.N,
          dateEdit: element.dateEdit != undefined ? element.dateEdit.N : "",
          cost: element.cost.N,
          currency: element.currency.S,
          street: element.street.S,
          region: element.region.S,
          floor: element.floor.N,
          state: element.state.S,
          heating: element.heating.S,
          info: element.info.S,
          furniture: element.furniture.BOOL,
          technology: element.technology.BOOL,
        });
      });

      if (items.length < 100 && lastEvaluatedKeyArchives != null) {
        listArchives(true, lastEvaluatedKeyArchives, items);
        return;
      }

      setArchiveItems(items);

      items.map((item) => {
        if (streetSearchTerms.indexOf(item.street) === -1)
          streetSearchTerms.push(item.street);
        if (regionSearchTerms.indexOf(item.region) === -1)
          regionSearchTerms.push(item.region);
      });
    } catch (err) {
      alert(err);
      console.error(err);
    }
    setCurrentPage("Archives");
    setShowLoading(false);
  };

  //query archives
  const queryArchives = async (
    loadNext = false,
    lastEvaluatedKeyArchives,
    street,
    rooms,
    region,
    heating,
    fromCost,
    toCost,
    prevItems
  ) => {
    setShowLoading(true);
    console.log(`loadNext: ${loadNext}`);
    try {
      if (loadNext && lastEvaluatedKeyArchives == null) {
        setShowLoading(false);
        return;
      }

      if (!loadNext) {
        if (
          street === null ||
          rooms === null ||
          region === null ||
          heating === null ||
          fromCost === null ||
          toCost === null
        ) {
          console.log("some of the items are null!");
          return;
        }
        if (
          street.length === 0 &&
          rooms.length === 0 &&
          region.length === 0 &&
          heating.length === 0 &&
          fromCost.length === 0 &&
          toCost.length === 0
        ) {
          listArchives();
          return;
        }
        var filterExpression = "";
        var expressionAttributeNames = {
          "#global": "global",
        };
        var expressionAttributeValues = {
          ":global": { N: "0" },
        };

        if (street.length !== 0) {
          filterExpression = "contains(#street, :street)";
          expressionAttributeNames["#street"] = "street";
          expressionAttributeValues[":street"] = {
            S: `${street}`,
          };
        }

        if (rooms.length !== 0) {
          expressionAttributeNames["#rooms"] = "rooms";
          expressionAttributeValues[":rooms"] = {
            N: `${rooms}`,
          };
          if (filterExpression.length < 1) {
            filterExpression = "#rooms = :rooms";
          } else {
            filterExpression += " AND #rooms = :rooms";
          }
        }

        if (region.length !== 0) {
          expressionAttributeNames["#region"] = "region";
          expressionAttributeValues[":region"] = {
            S: region,
          };
          if (filterExpression.length < 1) {
            filterExpression = "contains(#region, :region)";
          } else {
            filterExpression += " AND contains(#region, :region)";
          }
        }

        if (heating.length !== 0) {
          expressionAttributeNames["#heating"] = "heating";
          expressionAttributeValues[":heating"] = {
            S: heating,
          };
          if (filterExpression.length < 1) {
            filterExpression = "contains(#heating, :heating)";
          } else {
            filterExpression += " AND contains(#heating, :heating)";
          }
        }

        if (fromCost.length !== 0 || toCost.length !== 0) {
          expressionAttributeNames["#cost"] = "cost";
        }

        if (fromCost.length !== 0 && toCost.length !== 0) {
          if (filterExpression.length < 1) {
            filterExpression += "#cost BETWEEN :fromCost AND :toCost";
          } else {
            filterExpression += " AND #cost BETWEEN :fromCost AND :toCost";
          }
          expressionAttributeValues[":fromCost"] = {
            N: fromCost,
          };
          expressionAttributeValues[":toCost"] = {
            N: toCost,
          };
        } else {
          if (fromCost.length !== 0) {
            if (filterExpression.length < 1) {
              filterExpression += "#cost >= :fromCost";
            } else {
              filterExpression += " AND #cost >= :fromCost";
            }
            expressionAttributeValues[":fromCost"] = {
              N: fromCost,
            };
          }

          if (toCost.length !== 0) {
            if (filterExpression.length < 1) {
              filterExpression += "#cost <= :toCost";
            } else {
              filterExpression += " AND #cost <= :toCost";
            }
            expressionAttributeValues[":toCost"] = {
              N: toCost,
            };
          }
        }

        console.log(`query filter: ${filterExpression}`);
      } else {
        console.log("query next: ", lastEvaluatedKeyArchives);
        console.log(
          `query filter: ${lastQueryPropertiesArchives.FilterExpression}`
        );
        filterExpression = lastQueryPropertiesArchives.FilterExpression;
        expressionAttributeValues =
          lastQueryPropertiesArchives.ExpressionAttributeValues;
        expressionAttributeNames =
          lastQueryPropertiesArchives.ExpressionAttributeNames;
      }

      var data = await ddbClient.send(
        new QueryCommand({
          TableName: TABLE_NAME_ARCHIVES,
          IndexName: "global-dateTerm-index",
          KeyConditionExpression: "#global = :global",
          FilterExpression: filterExpression,
          ExpressionAttributeNames: expressionAttributeNames,
          ExpressionAttributeValues: expressionAttributeValues,
          Limit: SCAN_LIMIT,
          ExclusiveStartKey: loadNext ? lastEvaluatedKeyArchives : null,
          // ProjectionExpression: OBJECTS_PROJECTION,
        })
      );

      lastQueryPropertiesArchives = {
        FilterExpression: filterExpression,
        ExpressionAttributeValues: expressionAttributeValues,
        ExpressionAttributeNames: expressionAttributeNames,
      };
      lastEvaluatedKeyArchives = data.LastEvaluatedKey;

      console.log("lastKey: ", data.LastEvaluatedKey);

      var items = [];
      if (prevItems != null) items = [...prevItems];
      data.Items.forEach(function(element, index, array) {
        // var date = new Date();
        // date.setTime(element.dateTerm.N);
        // date = `${date.getUTCMonth() + 1}/${date.getUTCDate() +
        //   1}/${date.getFullYear()}`;
        items.push({
          id: element.id.S,
          personName: element.personName.S,
          phoneNumber: element.phoneNumber.S,
          rooms: element.rooms.N,
          dateFrom: element.dateFrom.N,
          dateDeleted: element.dateDeleted.N,
          dateTerm: element.dateTerm.N,
          dateEdit: element.dateEdit != undefined ? element.dateEdit.N : "0",
          cost: element.cost.N,
          currency: element.currency.S,
          street: element.street.S,
          region: element.region.S,
          floor: element.floor.N,
          state: element.state.S,
          heating: element.heating.S,
          info: element.info.S,
          furniture: element.furniture.BOOL,
          technology: element.technology.BOOL,
        });
      });

      if (items.length < 100 && lastEvaluatedKeyArchives != null) {
        await timeout(200);
        console.log("Waiting 200ms");
        queryArchives(
          true,
          lastEvaluatedKeyArchives,
          null,
          null,
          null,
          null,
          null,
          null,
          items
        );
        return;
      }

      console.log(items);

      setArchiveItems(items);
    } catch (err) {
      alert(err);
      console.error(err);
      // await timeout(1000); //wait for 1 sec
      // console.log("Trying again");
      // queryArchives(
      //   loadNext,
      //   lastEvaluatedKeyArchives,
      //   street,
      //   rooms,
      //   region,
      //   heating,
      //   fromCost,
      //   toCost,
      //   items
      // );
    }
    setShowLoading(false);
  };

  const loadNextObjects = async () => {
    if (lastQueryPropertiesObjects == null) {
      listObjects(true, lastEvaluatedKeyObjects, realEstateItems);
    } else {
      console.log("query OBJECTS");
      queryObjects(
        true,
        lastEvaluatedKeyObjects,
        null,
        null,
        null,
        null,
        null,
        null,
        realEstateItems
      );
    }
  };

  const loadNextClients = async () => {
    if (lastQueryPropertiesClients == null) {
      listClients(true, lastEvaluatedKeyClients, clientItems);
    } else {
      queryClients(
        true,
        lastEvaluatedKeyClients,
        null,
        null,
        null,
        null,
        null,
        clientItems
      );
    }
  };

  const loadNextArchives = async () => {
    if (lastQueryPropertiesArchives == null) {
      listArchives(true, lastEvaluatedKeyArchives, archiveItems);
    } else {
      console.log("query ARCHIVES");
      queryArchives(
        true,
        lastEvaluatedKeyArchives,
        null,
        null,
        null,
        null,
        null,
        null,
        archiveItems
      );
    }
  };

  //add real estate object
  const addObjectItem = async (item, inputFiles) => {
    if (ddbClient == null) {
      // alert("db client == null");
      return;
    }

    setShowLoading(true);
    setShowAddRealEstateObject(false);

    try {
      console.log(item);

      await ddbClient.send(
        new PutItemCommand({
          TableName: TABLE_NAME_OBJECTS,
          Item: {
            id: { S: `${item.id}` },
            global: { N: "0" },
            personName: { S: item.personName },
            phoneNumber: { S: item.phoneNumber },
            dateFrom: { N: `${item.dateFrom}` },
            dateEdit: { N: `${item.dateEdit}` },
            rooms: { N: `${item.rooms}` },
            cost: { N: `${item.cost}` },
            currency: { S: item.currency },
            street: {
              S: item.street,
            },
            region: { S: item.region },
            floor: { N: `${item.floor}` },
            state: { S: item.state },
            heating: {
              S: item.heating,
            },
            info: { S: item.info },
            furniture: { BOOL: item.furniture },
            technology: { BOOL: item.technology },
          },
        })
      );
      var items = realEstateItems.filter(
        (estateItem) => estateItem.id !== item.id
      );
      items.push(item);
      setRealEstateItems(items);
      console.log("add success");

      if (inputFiles != null) {
        var albumPhotosKey = encodeURIComponent(item.id) + "/";

        var tasks = [];

        for (var i = 0; i < inputFiles.length; i++) {
          tasks.push(
            s3Client.send(
              new PutObjectCommand({
                Bucket: S3_PHOTOS_BUCKET,
                Key: albumPhotosKey + inputFiles[i].name,
                Body: inputFiles[i],
              })
            )
          );
          console.log("push task");
        }

        await Promise.all(tasks);

        console.log("photo upload success");
      }
    } catch (err) {
      alert(err);
      console.error(err);
    }
    setShowLoading(false);
  };

  //add client
  const addClientItem = async (item) => {
    if (ddbClient == null) {
      // alert("db client == null");
      return;
    }

    setShowLoading(true);
    setShowAddClients(false);

    try {
      console.log(item.id);
      console.log(item.personName);

      await ddbClient.send(
        new PutItemCommand({
          TableName: TABLE_NAME_CLIENTS,
          Item: {
            id: { S: `${item.id}` },
            global: { N: "0" },
            dateFrom: { S: item.dateFrom },
            dateEdit: { N: `${item.dateEdit}` },
            rooms: { N: `${item.rooms}` },
            cost: { N: `${item.cost}` },
            currency: { S: item.currency },
            region: { S: item.region },
            aboutClient: { S: item.aboutClient },
            deadline: { N: `${item.deadline}` },
            urgency: { S: item.urgency },
            personName: { S: item.personName },
            phoneNumber: { S: item.phoneNumber },
            info: { S: item.info },
            rooms_region: {
              S: `${item.rooms}_${item.region}`,
            },
          },
        })
      );
      var items = clientItems.filter((clientItem) => clientItem.id !== item.id);
      items.push(item);
      setClientItems(items);

      // setClientItems([...clientItems, item]);
      console.log("add success");
    } catch (err) {
      alert(err);
      console.error(err);
    }
    setShowLoading(false);
  };

  //add item to archive
  const addArchiveItem = async (item) => {
    if (ddbClient == null) {
      // alert("db client == null");
      return;
    }

    setShowLoading(true);

    try {
      console.log(item);

      await ddbClient.send(
        new PutItemCommand({
          TableName: TABLE_NAME_ARCHIVES,
          Item: {
            id: { S: `${item.id}` },
            global: { N: "0" },
            personName: { S: item.personName },
            phoneNumber: { S: item.phoneNumber },
            dateFrom: { N: `${item.dateFrom}` },
            dateDeleted: { N: `${item.dateDeleted}` },
            dateTerm: { N: `${item.dateTerm}` },
            dateEdit: { N: `${item.dateEdit}` },
            rooms: { N: `${item.rooms}` },
            cost: { N: `${item.cost}` },
            currency: { S: item.currency },
            street: {
              S: item.street,
            },
            region: { S: item.region },
            floor: { N: `${item.floor}` },
            state: { S: item.state },
            heating: {
              S: item.heating,
            },
            info: { S: item.info },
            furniture: { BOOL: item.furniture },
            technology: { BOOL: item.technology },
          },
        })
      );
      var items = archiveItems.filter(
        (archiveItem) => archiveItem.id !== item.id
      );
      items.push(item);
      setArchiveItems(items);
      // setRealEstateItems([...realEstateItems, item]);
      console.log("add success");
    } catch (err) {
      alert(err);
      console.error(err);
    }
    setShowLoading(false);
  };

  //add item to archive
  const onEditArchiveItem = async (item, inputFiles) => {
    if (ddbClient == null) {
      // alert("db client == null");
      return;
    }

    setShowLoading(true);

    try {
      console.log(item);

      await ddbClient.send(
        new PutItemCommand({
          TableName: TABLE_NAME_ARCHIVES,
          Item: {
            id: { S: `${item.id}` },
            global: { N: "0" },
            personName: { S: item.personName },
            phoneNumber: { S: item.phoneNumber },
            dateFrom: { N: `${item.dateFrom}` },
            dateDeleted: { N: `${item.dateDeleted}` },
            dateTerm: { N: `${item.dateTerm}` },
            rooms: { N: `${item.rooms}` },
            cost: { N: `${item.cost}` },
            currency: { S: item.currency },
            street: {
              S: item.street,
            },
            region: { S: item.region },
            floor: { N: `${item.floor}` },
            state: { S: item.state },
            heating: {
              S: item.heating,
            },
            info: { S: item.info },
            furniture: { BOOL: item.furniture },
            technology: { BOOL: item.technology },
          },
        })
      );
      var items = archiveItems.filter(
        (archiveItem) => archiveItem.id !== item.id
      );
      items.push(item);
      setArchiveItems(items);
      console.log("add success");

      if (inputFiles != null) {
        var albumPhotosKey = encodeURIComponent(item.id) + "/";

        var tasks = [];

        for (var i = 0; i < inputFiles.length; i++) {
          tasks.push(
            s3Client.send(
              new PutObjectCommand({
                Bucket: S3_PHOTOS_BUCKET,
                Key: albumPhotosKey + inputFiles[i].name,
                Body: inputFiles[i],
              })
            )
          );
          console.log("push task");
        }

        await Promise.all(tasks);

        console.log("photo upload success");
      }
    } catch (err) {
      alert(err);
      console.error(err);
    }
    setShowLoading(false);
  };

  //add item to archive
  const addDeletedArchiveItem = async (item) => {
    if (ddbClient == null) {
      // alert("db client == null");
      return;
    }

    setShowLoading(true);

    try {
      console.log(item);

      await ddbClient.send(
        new PutItemCommand({
          TableName: TABLE_NAME_DELETED_ARCHIVE,
          Item: {
            id: { S: `${item.id}` },
            global: { N: "0" },
            personName: { S: item.personName },
            phoneNumber: { S: item.phoneNumber },
            dateFrom: { N: `${item.dateFrom}` },
            dateDeleted: { N: `${item.dateDeleted}` },
            dateTerm: { N: `${item.dateTerm}` },
            dateEdit: { N: `${item.dateEdit}` },
            rooms: { N: `${item.rooms}` },
            cost: { N: `${item.cost}` },
            currency: { S: item.currency },
            street: {
              S: item.street,
            },
            region: { S: item.region },
            floor: { N: `${item.floor}` },
            state: { S: item.state },
            heating: {
              S: item.heating,
            },
            info: { S: item.info },
            furniture: { BOOL: item.furniture },
            technology: { BOOL: item.technology },
          },
        })
      );
      // setRealEstateItems([...realEstateItems, item]);
      console.log("add success");
    } catch (err) {
      alert(err);
      console.error(err);
    }
    setShowLoading(false);
  };

  const beforeDeleteObject = (id) => {
    setActiveItemId(id);
    setOpenDeleteDialog(true);
    return;
  };

  const beforeReturnObject = (id) => {
    setActiveItemId(id);
    setOpenReturnDialog(true);
    return;
  };

  const beforeDeleteArchive = (id) => {
    setActiveItemId(id);
    setOpenDeleteArchiveDialog(true);
    return;
  };

  const returnItem = async (id) => {
    //console.log(item);
    archiveItems
      .filter((item) => item.id === id)
      .map((item) => {
        item.dateEdit = new Date(
          `${new Date().getUTCMonth() +
            1}/${new Date().getUTCDate()}/${new Date().getFullYear()}`
        ).getTime();
        addObjectItem(item, null);
      });
    removeFromArchive(id);
  };

  const removeFromArchive = async (id) => {
    if (ddbClient == null) {
      // alert("db client == null");
      return;
    }

    setShowLoading(true);

    try {
      await ddbClient.send(
        new DeleteItemCommand({
          TableName: TABLE_NAME_ARCHIVES,
          Key: {
            id: { S: id },
          },
        })
      );
      setArchiveItems(
        archiveItems.filter((archiveItem) => archiveItem.id !== id)
      );
      console.log("delete success");
    } catch (err) {
      alert(err);
      console.error(err);
    }
    setShowLoading(false);
  };

  const deleteArchive = async (id) => {
    if (ddbClient == null) {
      // alert("db client == null");
      return;
    }

    setShowLoading(true);

    archiveItems.map((item) => {
      if (item.id === id) addDeletedArchiveItem(item);
    });

    try {
      await ddbClient.send(
        new DeleteItemCommand({
          TableName: TABLE_NAME_ARCHIVES,
          Key: {
            id: { S: id },
          },
        })
      );
      setArchiveItems(
        archiveItems.filter((archiveItem) => archiveItem.id !== id)
      );
      console.log("delete success");

      const albumKey = encodeURIComponent(id) + "/";

      const photosData = await s3Client.send(
        new ListObjectsCommand({
          Bucket: S3_PHOTOS_BUCKET,
          Prefix: albumKey,
        })
      );

      if (photosData.Contents != null) {
        const objects = photosData.Contents.map(function(object) {
          return { Key: object.Key };
        });

        await s3Client.send(
          new DeleteObjectsCommand({
            Bucket: S3_PHOTOS_BUCKET,
            Delete: { Objects: objects },
            Quiet: true,
          })
        );

        console.log("photos deleted");
      }
    } catch (err) {
      alert(err);
      console.error(err);
    }
    setShowLoading(false);
  };

  //delete real estate object
  const deleteItem = async (id, date) => {
    if (ddbClient == null) {
      // alert("db client == null");
      return;
    }

    setShowLoading(true);

    realEstateItems
      .filter((item) => item.id === id)
      .map((item) => {
        item.dateDeleted = new Date(
          `${new Date().getUTCMonth() +
            1}/${new Date().getUTCDate()}/${new Date().getFullYear()}`
        ).getTime();
        item.dateTerm = new Date(
          `${date.displayDateFrom.getUTCMonth() +
            1}/${date.displayDateFrom.getUTCDate()}/${date.displayDateFrom.getFullYear()}`
        ).getTime();
        item.dateTo = "";
        addArchiveItem(item);
      });

    try {
      await ddbClient.send(
        new DeleteItemCommand({
          TableName: TABLE_NAME_OBJECTS,
          Key: {
            id: { S: id },
          },
        })
      );
      setRealEstateItems(
        realEstateItems.filter((realEstateItem) => realEstateItem.id !== id)
      );
      console.log("delete success");

      // const albumKey = encodeURIComponent(id) + "/";

      // const photosData = await s3Client.send(
      //   new ListObjectsCommand({
      //     Bucket: S3_PHOTOS_BUCKET,
      //     Prefix: albumKey,
      //   })
      // );

      // if (photosData.Contents != null) {
      //   const objects = photosData.Contents.map(function (object) {
      //     return { Key: object.Key };
      //   });

      //   await s3Client.send(
      //     new DeleteObjectsCommand({
      //       Bucket: S3_PHOTOS_BUCKET,
      //       Delete: { Objects: objects },
      //       Quiet: true,
      //     })
      //   );

      //   console.log("photos deleted");
      // }
    } catch (err) {
      alert(err);
      console.error(err);
    }
    setShowLoading(false);
  };

  //delete client object
  const deleteClientItem = async (id) => {
    if (ddbClient == null) {
      // alert("db client == null");
      return;
    }

    setShowLoading(true);

    try {
      await ddbClient.send(
        new DeleteItemCommand({
          TableName: TABLE_NAME_CLIENTS,
          Key: {
            id: { S: id },
          },
        })
      );
      setClientItems(clientItems.filter((clientItem) => clientItem.id !== id));
      console.log("delete success");
    } catch (err) {
      alert(err);
      console.error(err);
    }
    setShowLoading(false);
  };

  useEffect(() => {
    const ac = new AbortController();
    if (ddbClient == null) {
      return;
    }
    if (!displayAdminTools) setDisplayAdminTools(true);

    if (admin == null) {
      checkAdmin();
    }

    return () => ac.abort();
  }, []);

  const openViewPage = async (rowValues) => {
    window.open(`View/${rowValues.row.id}`);
  };

  // ddbClient == null ||
  //     admin == null ||
  //     (window.location.pathname !== "/" &&
  //       window.location.pathname !== "/Clients" &&
  //       window.location.pathname !== "/Archives") ? (
  //     <div>
  //       {console.log(`Other page: ${window.location.pathname}`)}
  //       <HashRouter>
  //         <Routes>
  //           <Route path="/View" element={<AppClient />}></Route>
  //         </Routes>
  //       </HashRouter>
  //     </div>) : (
  return ddbClient == null || admin == null ? (
    <div></div>
  ) : (
    <div>
      <BuildingInfo
        handleCloseInfo={() => setOpenInfo(false)}
        openInfo={openInfo}
        infoText={infoText}
      />
      <StandardImageList
        handleClosePhotos={() => setOpenPhotos(false)}
        openPhotos={openPhotos}
        photos={photos}
        onDeletePhoto={onDeletePhoto}
        admin={admin}
      />
      {displayAdminTools && (
        <ResponsiveAppBar
          listClients={listClients}
          listObjects={listObjects}
          listArchives={listArchives}
          admin={admin}
        />
      )}
      <BeforeDeleteObject
        openDialog={openDeleteDialog}
        id={activeItemId}
        deleteObject={deleteItem}
        handleCloseDialog={() => setOpenDeleteDialog(false)}
      />
      <BeforeDeleteArchive
        openDialog={openDeleteArchiveDialog}
        id={activeItemId}
        deleteObject={deleteArchive}
        handleCloseDialog={() => setOpenDeleteArchiveDialog(false)}
      />
      <BeforeReturnArchive
        openDialog={openReturnDialog}
        id={activeItemId}
        returnItem={returnItem}
        handleCloseDialog={() => setOpenReturnDialog(false)}
      />
      {currentPage === "Objects" && (
        <RealEstateObjectsPage
          onAddItem={() => setShowAddRealEstateObject(true)}
          addItem={addObjectItem}
          showLoading={showLoading}
          showAddRealEstateObject={showAddRealEstateObject}
          onCloseDialog={() => setShowAddRealEstateObject(false)}
          onCloseEditDialog={() => setIsEditItem(false)}
          realEstateItems={realEstateItems}
          items={realEstateItems}
          deleteItem={deleteItem}
          handleOpenInfo={handleOpenInfo}
          handleOpenPhotos={handleOpenPhotos}
          handleEditItem={handleEditItem}
          handleDeleteItem={beforeDeleteObject}
          queryObjects={filterObjects}
          listObjects={listObjects}
          streetSearchTerms={streetSearchTerms}
          regionSearchTerms={regionSearchTerms}
          isEditItem={isEditItem}
          item={editItem}
          onPageChanged={onTablePageChange}
          loadNextObjects={loadNextObjects}
          admin={admin}
          openViewPage={openViewPage}
        />
      )}
      {currentPage === "Clients" && (
        <ClientsPage
          onAddItem={() => setShowAddClients(true)}
          addItem={addClientItem}
          showLoading={showLoading}
          showAddClient={showAddClients}
          onCloseDialog={() => setShowAddClients(false)}
          onCloseEditDialog={() => setIsEditItem(false)}
          clientItems={clientItems}
          items={clientItems}
          deleteItem={deleteClientItem}
          handleOpenInfo={handleOpenClientInfo}
          handleEditItem={handleEditClientItem}
          handleDeleteItem={deleteClientItem}
          queryClients={queryClients}
          regionSearchTerms={clientRegionSearchTerms}
          isEditItem={isEditItem}
          item={editClientItem}
          loadNextClients={loadNextClients}
          admin={admin}
        />
      )}
      {currentPage === "Archives" && (
        <ArchivesPage
          release={!PRODUCTION}
          items={archiveItems}
          showLoading={showLoading}
          deleteItem={beforeDeleteArchive}
          openArchiveInfo={openArchiveInfo}
          handleOpenPhotos={handleOpenPhotos}
          handleEditItem={handleEditArchiveItem}
          returnItem={beforeReturnObject}
          loadNextArchives={loadNextArchives}
          openViewPage={openViewPage}
          editArchiveItem={editArchiveItem}
          onEditItem={onEditArchiveItem}
          isEditItem={isEditItem}
          regionSearchTerms={regionSearchTerms}
          streetSearchTerms={streetSearchTerms}
          onCloseEditDialog={() => setIsEditItem(false)}
          queryObjects={queryArchives}
        />
      )}
    </div>
  );
}

export default AppRealtor;
