import { useState } from "react";
import React from "react";
import {
  Box,
  Button,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import BoxInput from "./BoxInput";
import { createTheme, ThemeProvider, Stack } from "@mui/material";

const theme = createTheme({
  spacing: 4,
});

const Header = ({
  onAddItem,
  queryObjects,
  regionSearchTerms,
  streetSearchTerms,
  admin,
}) => {
  const getTypes = () => {
    console.log(
      `street type: ${typeof street} ${street}\nrooms type: ${typeof rooms} ${rooms}\nregions type: ${typeof regions} ${regions}\ncostFrom type: ${typeof fromCost} ${fromCost}\ncostTo type: ${typeof toCost} ${toCost}`
    );
  };

  const [street, setStreet] = useState("");
  const [rooms, setRooms] = useState(-1);
  const [regions, setRegions] = useState("");
  const [heating, setHeating] = useState("");
  const [fromCost, setCostFrom] = useState(-1);
  const [toCost, setCostTo] = useState(-1);
  var filterProps = {
    street: "",
    rooms: -1,
    region: "",
    heating: "",
    fromCost: -1,
    toCost: -1,
  };

  return (
    <ThemeProvider theme={theme}>
      <div
        className="custom_scrollbar"
        style={{
          overflowX: "scroll",
        }}
      >
        <Box
          minWidth="100%"
          display="inline-flex"
          sx={{
            border: 1,
            borderColor: "#b8b8b8",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Box p={2}>
            {admin && (
              <Button
                variant="outlined"
                onClick={onAddItem}
                sx={{ height: "40px" }}
              >
                <Typography variant="body2" fontSize={12} display="inline">
                  Додати обєкт
                </Typography>
              </Button>
            )}
          </Box>
          <Box
            width="100%"
            sx={{
              alignItems: "center",
              alignContent: "right",
              justifyContent: "flex-end",
            }}
            display="inline-flex"
          >
            <Stack spacing={2} direction="row" alignItems="center">
              <Typography variant="body2" component="body2">
                Назва вулиці
              </Typography>
              <BoxInput
                type="text"
                onChange={(newValue) => {
                  setStreet(newValue);
                  filterProps.street = newValue;
                }}
                items={streetSearchTerms}
              />
              <Typography variant="body2" component="body2">
                К-сть кімнат
              </Typography>
              <BoxInput
                type="number"
                inputWidth="40px"
                items={["1", "2", "3"]}
                onChange={(newValue) => {
                  setRooms(newValue);
                  filterProps.rooms = newValue;
                  console.log("changed");
                }}
              />
              <Typography variant="body2" component="body2">
                Район
              </Typography>
              <BoxInput
                items={regionSearchTerms}
                type="text"
                onChange={(newValue) => {
                  setRegions(newValue);
                  filterProps.regions = newValue;
                }}
              />
              <Typography variant="body2" component="body2">
                Опалення
              </Typography>
              <BoxInput
                items={["Центральне", "Індивідуальне"]}
                type="text"
                onChange={(newValue) => {
                  setHeating(newValue);
                  filterProps.heating = newValue;
                }}
              />{" "}
              <Typography variant="body2" component="body2">
                Від
              </Typography>
              <BoxInput
                inputWidth="75px"
                items={["6000"]}
                type="number"
                onChange={(newValue) => {
                  setCostFrom(newValue);
                  filterProps.fromCost = newValue;
                }}
              />
              <Typography variant="body2" component="body2">
                До
              </Typography>
              <BoxInput
                inputWidth="75px"
                items={["12000"]}
                type="number"
                onChange={(newValue) => {
                  setCostTo(newValue);
                  filterProps.toCost = newValue;
                }}
              />
              <Box p={2}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    getTypes();
                    queryObjects({
                      street: street,
                      rooms: rooms,
                      region: regions,
                      heating: heating,
                      fromCost: fromCost,
                      toCost: toCost,
                    });
                  }}
                >
                  <Typography
                    variant="body2"
                    component="body2"
                    fontStyle="normal"
                  >
                    Знайти
                  </Typography>
                </Button>
              </Box>
            </Stack>
          </Box>
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default Header;
