import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Container, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const BeforeReturnArchive = ({
  openDialog,
  id,
  returnItem,
  handleCloseDialog,
}) => {
  const handleReturnItem = () => {
    handleCloseDialog();
    returnItem(id);
  };

  return (
    <Modal
      open={openDialog}
      display="flex"
      justifyContent="center"
      onClose={() => {
        handleCloseDialog();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        style={{
          display: "flex",
          backgroundColor: "#FFFFFF",
          width: 200,
          minHeight: 100,
          height: "auto",
          paddingBottom: "10px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          alignItems: "center",
          //justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <Box m="auto" justifyContent="center" display="flex">
          <Typography
            id="modal-modal-title"
            variant="body2"
            component="h2"
            fontWeight="Light"
            textAlign="center"
            paddingTop={1}
            paddingBottom={1}
            fontSize={16}
          >
            Вернути об'єкт?
          </Typography>
        </Box>
        <Stack direction="row" spacing={2} width="100%" justifyContent="center">
          <Button
            variant="outlined"
            onClick={handleCloseDialog}
            sx={{ fontWeight: 300 }}
          >
            Ні
          </Button>
          <Button
            variant="outlined"
            onClick={handleReturnItem}
            sx={{ fontWeight: 300 }}
          >
            Так
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default BeforeReturnArchive;

BeforeReturnArchive.defaultProps = {
  infoText: "Немає додаткових даних",
};
