import React from "react";
import {
  Container,
  Divider,
  Paper,
  Stack,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import {
  ChevronRight,
  ChevronLeft,
  LocationOnOutlined,
} from "@mui/icons-material";
import { useRef, useState } from "react";
import Link from "@mui/material/Link";
import ViewImage from "../dialogs/ViewImage";
import ReactLinkify from "react-linkify";

const ViewObjectPage = ({ viewPageValues }) => {
  const ref = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const handleCloseModal = () => {
    if (openModal) setOpenModal(false);
  };

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
    console.log(`scroll: ${scrollOffset}`);
  };
  //"this is my [Ютуб](https://www.google.com/)"

  var text = viewPageValues.info != undefined ? viewPageValues.info : "";

  const [linkC, setLinkC] = useState("");

  const regex = /(.+)\[(.+)\](.+)/;

  const anchor = text.replace(regex, (match, a, b, c) => {
    text = `${a} ${c}`;
    console.log(text);
    if (linkC === "") setLinkC(b);
    return `<Typography fontWeight={300} paddingBottom={2}>${text} </Typography><Link fontFamily="roboto"
              hrefLang="ua"
              href="${b}">${b}</Link>`;
  });

  console.log(linkC);

  return (
    <div
      style={{
        overflowX: "hidden",
        minHeight: "100vh",
        background:
          "radial-gradient(farthest-side at 10% 0px, rgb(182 220 255) 20%, rgb(221 244 255))",
      }}
    >
      <ViewImage
        viewPageValues={viewPageValues}
        openModal={openModal}
        photoIndex={photoIndex}
        handleCloseModal={handleCloseModal}
      />
      {/* {console.log(viewPageValues)} */}
      <Container
        maxWidth="lg"
        style={{
          background: "rgb(255 255 255 / 28%)",
          marginTop: 10,
          borderRadius: 3,
          minHeight: 200,
        }}
      >
        <Stack
          spacing={2}
          divider={
            <Divider
              orientation="horizontal"
              flexItem
              style={{ borderColor: "rgb(116 116 116 / 38%)" }}
            />
          }
          paddingTop={2}
        >
          <Typography
            fontWeight={500}
          >{`Оренда ${viewPageValues.rooms}-к квартири м.Львів, ${viewPageValues.region} район, вул. ${viewPageValues.street}`}</Typography>
          <div
            className="custom_scrollbar"
            style={{ overflowX: "scroll", scrollBehavior: "smooth" }}
            ref={ref}
          >
            <Box minWidth="100%" display="flex">
              <Stack id="image-stack" direction="row" spacing={2}>
                {viewPageValues.Photos != undefined ? (
                  viewPageValues.Photos.map((item) => (
                    <div>
                      <img
                        style={{ maxHeight: "150px", cursor: "pointer" }}
                        src={`${item.url}`}
                        srcSet={`${item.url}`}
                        loading="lazy"
                        key={item.key}
                        onClick={() => {
                          setPhotoIndex(viewPageValues.Photos.indexOf(item));
                          setOpenModal(true);
                        }}
                      />
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </Stack>
              <Box
                minWidth="max-content"
                left="10%"
                right="10%"
                height="150px"
                display="flex"
                justifyContent="space-between"
                position="absolute"
                alignItems="center"
                style={{ pointerEvents: "none" }}
              >
                <IconButton
                  style={{ pointerEvents: "all" }}
                  onClick={() => {
                    scroll(-300);
                  }}
                >
                  {viewPageValues.Photos != undefined && <ChevronLeft />}
                </IconButton>
                <IconButton
                  style={{ pointerEvents: "all" }}
                  id="right-arrow"
                  onClick={() => {
                    scroll(300);
                  }}
                >
                  {viewPageValues.Photos != undefined && <ChevronRight />}
                </IconButton>
              </Box>
            </Box>
          </div>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box width={50}>
              <Typography fontWeight={500}>Адрес:</Typography>
            </Box>

            <Typography fontWeight={300} paddingLeft={5}>
              {`${viewPageValues.region} район`}
            </Typography>
            <LocationOnOutlined color="primary" />
            <Link
              fontFamily="roboto"
              hrefLang="ua"
              href={`https://www.google.com/maps/search/${
                viewPageValues.region
              } район, Львів, вулиця ${
                viewPageValues.street &&
                viewPageValues.street.replace("продз", "")
              }`}
            >
              Показати на карті
            </Link>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Box width={50}>
              <Typography fontWeight={500}>Ціна:</Typography>
            </Box>

            <Typography fontWeight={300} paddingLeft={5}>
              {`${viewPageValues.cost} ${viewPageValues.currency}`}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Box width={50}>
              <Typography fontWeight={500}>Кімнат:</Typography>
            </Box>

            <Typography fontWeight={300} paddingLeft={5}>
              {viewPageValues.rooms}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Box width={50}>
              <Typography fontWeight={500}>Поверх:</Typography>
            </Box>

            <Typography fontWeight={300} paddingLeft={5}>
              {`${viewPageValues.floor} поверх`}
            </Typography>
          </Stack>
          {/* <ReactLinkify
            properties={{
              target: "_blank",
              style: {
                color: "#FFFFFF",
                paddingBottom: 2,
                fontFamily: "roboto",
              },
            }}
          >
            {viewPageValues.info}
          </ReactLinkify> */}
          <Typography fontWeight={300} paddingBottom={2}>
            {text}
            <Link fontFamily="roboto" hrefLang="ua" href={linkC}>
              {linkC}
            </Link>
          </Typography>
        </Stack>
      </Container>
    </div>
  );
};

export default ViewObjectPage;
