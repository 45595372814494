import { Dialog } from "@material-ui/core";
import React from "react";
import { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Button, Autocomplete, IconButton } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography, Alert } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import BasicAlerts from "../dialogs/MyAlert";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { Collections, Close } from "@mui/icons-material";

const EditArchive = ({
  onEditItem,
  onCloseDialog,
  regionSearchTerms,
  streetSearchTerms,
  item,
}) => {
  const Input = styled("input")({
    display: "none",
  });
  const handleFileInput = (e) => {
    var selectedFiles = [];

    if (!e.target.files || e.target.files.length === 0) {
      return;
    } else {
      //console.log(e.target.files);
      //selectedFiles = e.target.files;
      for (var i = 0; i < e.target.files.length; i++) {
        selectedFiles.push(e.target.files[i]);
      }

      setInputFiles([...inputFiles, ...selectedFiles]);
    }
  };

  const removeFile = (fileVar) => {
    setInputFiles(inputFiles.filter((file) => file != fileVar));
  };

  const inputFile = useRef(null);
  //var inputFiles = [];
  const [inputFiles, setInputFiles] = useState([]);
  const [personName, setPersonName] = useState(item.personName);
  const [phoneNumber, setPhoneNumber] = useState(item.phoneNumber);
  const [rooms, setRooms] = useState(item.rooms);
  const [cost, setCost] = useState(item.cost);
  const [currency, setCurrency] = useState(item.currency);
  const [street, setStreet] = useState(item.street);
  const [region, setRegion] = useState(item.region);
  const [floor, setFloor] = useState(item.floor);
  const [state, setState] = useState(item.state);
  const [heating, setHeating] = useState(item.heating);
  const [info, setInfo] = useState(item.info);
  const [furniture, setFurniture] = useState(item.furniture);
  const [technology, setTechnology] = useState(item.technology);
  const [displayDateTerm, setDisplayDateTerm] = useState(
    new Date(new Date().setTime(item.dateTerm))
  );
  console.log(
    `${personName}\n${phoneNumber}\n${displayDateTerm}\n${rooms}\n${cost}\n${currency}\n${street}\n${region}\n${floor}\n${state}\n${heating}`
  );

  console.log(item);

  const onSave = (e) => {
    e.preventDefault();

    item = {
      id: item.id,
      personName: personName,
      phoneNumber: phoneNumber,
      rooms: rooms,
      dateTerm: displayDateTerm.getTime(),
      dateFrom: item.dateFrom,
      dateDeleted: item.dateDeleted,
      cost: cost,
      currency: currency,
      street: street,
      region: region,
      floor: floor,
      state: state,
      heating: heating,
      info: info,
      furniture: furniture,
      technology: technology,
    };
    onEditItem(item, inputFiles);
    onCloseDialog();
  };

  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <TextField
          id="name"
          defaultValue={personName}
          label="Імя власника"
          variant="filled"
          type="text"
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          onChange={(e) => setPersonName(e.target.value)}
          fullWidth={true}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={displayDateTerm}
            onChange={(newValue) => {
              setDisplayDateTerm(newValue);
            }}
            placeholder="день/місяць/рік"
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "місяць/день/рік",
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") e.preventDefault();
                }}
                fullWidth={true}
                variant="filled"
                label="Термін"
              />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <TextField
          id="phone"
          defaultValue={phoneNumber}
          label="Номер телефону власника"
          variant="filled"
          type="text"
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          onChange={(e) => setPhoneNumber(e.target.value)}
          fullWidth={true}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <TextField
          id="filled-basic"
          label="Кількість кімнат"
          variant="filled"
          type="number"
          defaultValue={rooms}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          onChange={(e) => {
            setRooms(e.target.value);
          }}
          fullWidth={true}
          error={rooms === ""}
          helperText={rooms === "" ? "Важливе поле" : " "}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <TextField
          id="filled-basic"
          label="Ціна"
          variant="filled"
          type="number"
          defaultValue={cost}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          onChange={(e) => setCost(e.target.value)}
          fullWidth={true}
          error={cost === ""}
          helperText={cost === "" ? "Важливе поле" : " "}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <Autocomplete
          freeSolo
          options={["грн", "$"]}
          inputValue={currency}
          onInputChange={(e, newValue) => {
            setCurrency(newValue);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          fullWidth={true}
          renderInput={(params) => (
            <TextField
              {...params}
              type="text"
              variant="filled"
              label="Валюта"
            />
          )}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <Autocomplete
          freeSolo
          inputValue={street}
          options={streetSearchTerms.map((option) => option)}
          onInputChange={(e, newValue) => {
            setStreet(newValue);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          fullWidth={true}
          renderInput={(params) => (
            <TextField
              {...params}
              type="text"
              variant="filled"
              error={street === ""}
              helperText={street === "" ? "Важливе поле" : " "}
              label="Вулиця"
            />
          )}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <Autocomplete
          freeSolo
          inputValue={region}
          options={regionSearchTerms.map((option) => option)}
          onInputChange={(e, newValue) => {
            setRegion(newValue);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          fullWidth={true}
          renderInput={(params) => (
            <TextField
              {...params}
              type="text"
              variant="filled"
              error={region === ""}
              helperText={region === "" ? "Важливе поле" : " "}
              label="Район"
            />
          )}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <TextField
          id="filled-basic"
          label="Поверх"
          variant="filled"
          type="number"
          defaultValue={floor}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          onChange={(e) => setFloor(e.target.value)}
          fullWidth={true}
          error={floor === ""}
          helperText={floor === "" ? "Важливе поле" : " "}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <Autocomplete
          freeSolo
          inputValue={state}
          options={["Санітарний", "Під Євро", "Євро"]}
          onInputChange={(e, newValue) => {
            setState(newValue);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          fullWidth={true}
          renderInput={(params) => (
            <TextField {...params} type="text" variant="filled" label="Стан" />
          )}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <Autocomplete
          freeSolo
          inputValue={heating}
          options={["Центральне", "Індивідуальне"]}
          onInputChange={(e, newValue) => {
            setHeating(newValue);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          fullWidth={true}
          renderInput={(params) => (
            <TextField
              {...params}
              type="text"
              variant="filled"
              error={region === ""}
              helperText={region === "" ? "Важливе поле" : " "}
              label="Опалення"
            />
          )}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <TextField
          id="filled-basic"
          placeholder="Ставте посилання в [ ]"
          label="Докладна інформація"
          variant="filled"
          type="text"
          defaultValue={info}
          onChange={(e) => setInfo(e.target.value)}
          fullWidth={true}
          multiline
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="left"
        marginTop={1}
        marginLeft={1}
      >
        <FormControlLabel
          control={<Checkbox defaultChecked={furniture} checked={furniture} />}
          label="Меблі"
          onChange={(e) => setFurniture(e.currentTarget.checked)}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="left"
        marginLeft={1}
        marginBottom={1}
      >
        <FormControlLabel
          control={
            <Checkbox defaultChecked={technology} checked={technology} />
          }
          label="Технологія"
          onChange={(e) => setTechnology(e.currentTarget.checked)}
        />
      </Box>
      <Stack
        id="uploading-photos"
        direction="row"
        // border={1}
        // borderColor="#85858540"
        marginBottom={2}
        paddingLeft={1}
        spacing={2}
        alignItems="center"
      >
        <Typography>Додати фото:</Typography>
      </Stack>
      <Input
        multiple
        accept="image/*"
        id="contained-button-file"
        type="file"
        ref={inputFile}
        onChange={(e) => {
          handleFileInput(e);
        }}
      />
      <Box
        display="flex"
        justifyContent="flex-start"
        paddingLeft={1}
        paddingBottom={2}
      >
        <Button
          variant="contained"
          endIcon={<Collections />}
          style={{ backgroundColor: "#039dfc", maxHeight: 35 }}
          onClick={(e) => inputFile.current && inputFile.current.click()}
        >
          <Typography component="body2" fontWeight="Light">
            Додати
          </Typography>
        </Button>
        <Stack
          paddingLeft={2}
          display="flex"
          style={{ alignItems: "flex-start" }}
        >
          {inputFiles != null ? (
            inputFiles.map((file) => (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography
                  id="test_typhography"
                  component="body2"
                  fontWeight="Light"
                >
                  {file.name}
                </Typography>
                <IconButton
                  onClick={() => {
                    removeFile(file);
                  }}
                >
                  <Close />
                </IconButton>
              </Stack>
            ))
          ) : (
            <></>
          )}
        </Stack>
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <Button variant="outlined" onClick={onCloseDialog} sx={{ width: 100 }}>
          <Typography variant="body2" fontSize={12} display="inline">
            Закрити
          </Typography>
        </Button>
        <Button
          variant="outlined"
          onClick={(e) => {
            if (
              street !== "" &&
              rooms !== "" &&
              region !== "" &&
              heating !== "" &&
              floor !== "" &&
              cost !== ""
            ) {
              onSave(e);
            } else {
              // <BasicAlerts
              //   severity={"error"}
              //   text={"Будь ласка, заповніть важливі поля"}
              // />;
            }
          }}
          sx={{ width: 100, marginLeft: 2 }}
        >
          <Typography variant="body2" fontSize={12} display="inline">
            Змінити
          </Typography>
        </Button>
      </Box>
    </Dialog>
  );
};

export default EditArchive;
