import * as React from "react";
import { DataGrid, ukUA } from "@mui/x-data-grid";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  Info,
  PhotoCamera,
  Delete,
  Edit,
  Details,
  Visibility,
  VisibilityOff,
  Share,
} from "@mui/icons-material";
import { Container, Stack, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import "../styles.css";
import { isMobile } from "react-device-detect";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function DataTable({
  items,
  handleOpenInfo,
  handleOpenPhotos,
  handleDeleteItem,
  handleEditItem,
  onPageChanged,
  loadNextObjects,
  admin,
  openViewPage,
}) {
  const MAX_ROW_LENGTH = 50;
  const [visible, setVisible] = React.useState(""); //holds row id's
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const ref = React.useRef(null);
  const canonical = document.querySelector("link[rel=canonical]");
  var shareDetails = { url: "", title: "Оренда квартири", text: "" };
  var date = new Date();

  const theme = createTheme(ukUA);

  const handleSharing = async (params) => {
    if (navigator.share) {
      try {
        await navigator.share(shareDetails).then(() => {
          if (!isMobile) openViewPage(params);
          console.log("Shared");
        });
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`);
        openViewPage(params);
      }
    } else {
      // fallback code
      console.log(
        "Web share is currently not supported on this browser. Please provide a callback"
      );
      openViewPage(params);
    }
  };

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
    console.log(`scroll: ${scrollOffset}`);
  };

  const handleCloseShare = () => {
    setDialogVisible(false);
  };

  const onScroll = () => {
    //console.log(ref);
  };

  const getDate = (time) => {
    date.setTime(time);
    return `${date.getUTCMonth() + 1}/${date.getUTCDate() +
      1}/${date.getFullYear()}`;
  };

  var columns = [
    {
      field: "dateFrom",
      headerName: "Додано",
      width: 90,
      renderCell: (cellValues) => {
        return <>{getDate(cellValues.row.dateFrom)}</>;
      },
    },
    {
      field: "dateEdit",
      headerName: "Редаговано",
      width: 90,
      renderCell: (cellValues) => {
        return <>{getDate(cellValues.row.dateEdit)}</>;
      },
    },
    { field: "rooms", headerName: "Кімнат", width: 70, type: "number" },
    {
      field: "cost",
      headerName: "Ціна",
      width: 90,
      type: "number",
    },
    { field: "currency", headerName: "Валюта", width: 70 },
    {
      field: "street",
      headerName: "Вулиця",
      width: 110,
    },
    { field: "region", headerName: "Район", width: 110 },
    {
      field: "floor",
      headerName: "Поверх",
      width: 70,
      type: "number",
    },
    { field: "state", headerName: "Стан", width: 90 },
    { field: "heating", headerName: "Опалення", width: 110 },
    {
      field: "furniture",
      headerName: "Меблі",
      width: 70,
      type: "boolean",
    },
    {
      field: "technology",
      headerName: "Техніка",
      width: 70,
      type: "boolean",
    },
    { field: "personName", headerName: "Ім'я", width: 100 },
    {
      field: "phoneNumber",
      headerName: "Номер",
      width: 130,
      renderCell: (cellValues) => {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            {visible === cellValues.row.id ? (
              <>
                {cellValues.formattedValue}
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    setVisible("");
                  }}
                >
                  <VisibilityOff style={{ width: 15, height: 15 }} />
                </IconButton>
              </>
            ) : (
              <>
                {`******${cellValues.formattedValue.substring(
                  cellValues.formattedValue.length - 3,
                  cellValues.formattedValue.length
                )}`}
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    setVisible(cellValues.row.id);
                  }}
                >
                  <Visibility style={{ width: 15, height: 15 }} />
                </IconButton>
              </>
            )}
          </Stack>
        );
      },
    },
  ];

  if (admin) {
    columns.push({
      field: "info",
      type: "actions",
      width: 200,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Info />}
          onClick={() => handleOpenInfo(params.id)}
          label="Info"
        />,
        <GridActionsCellItem
          icon={<PhotoCamera />}
          label="Photos"
          onClick={() => handleOpenPhotos(params.id)}
        />,
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          onClick={() => handleEditItem(params.id)}
        />,
        <GridActionsCellItem
          icon={<Delete />}
          label="Delete"
          onClick={() => handleDeleteItem(params.id)}
        />,
        <GridActionsCellItem
          icon={<Share />}
          label="Share"
          onClick={async () => {
            if (!isMobile) {
              openViewPage(params);
            } else {
              shareDetails.url = `View/${params.id}`;
              shareDetails.text = `${params.row.info}`;
              await handleSharing(params);
            }
          }}
        />,
      ],
    });
  } else {
    columns.push({
      field: "info",
      type: "actions",
      width: 200,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Info />}
          onClick={() => handleOpenInfo(params.id)}
          label="Info"
        />,
        <GridActionsCellItem
          icon={<PhotoCamera />}
          label="Photos"
          onClick={() => handleOpenPhotos(params.id)}
        />,
        <GridActionsCellItem
          icon={<Share />}
          label="Share"
          onClick={async () => {
            if (!isMobile) {
              openViewPage(params);
            } else {
              shareDetails.url = `View/${params.id}`;
              shareDetails.text = `${params.row.info}`;
              await handleSharing(params);
            }
          }}
        />,
      ],
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          height: window.innerHeight - 110,
          width: "100%",
          flexGrow: 1,
          //overflowY: "scroll",
        }}
      >
        <Typography textAlign="block" variant="body2" component="body2">
          <DataGrid
            sx={{
              ".MuiDataGrid-cellContent": {
                overflowWrap: "break-word",
              },
            }}
            disableSelectionOnClick
            disableColumnFilter={true}
            hideFooterSelectedRowCount
            getRowHeight={() => "auto"}
            //getEstimatedRowHeight={() => 100}
            rows={items}
            columns={columns}
            pageSize={MAX_ROW_LENGTH}
            rowsPerPageOptions={[MAX_ROW_LENGTH]}
            showCellRightBorder
            showColumnRightBorder
            onPageChange={(pageNumber, details) => {
              onPageChanged(pageNumber, details.rows);
              if (items.length <= MAX_ROW_LENGTH * (pageNumber + 1)) {
                console.log("last page reached");
                loadNextObjects();
              }
            }}
          />
        </Typography>
      </div>
    </ThemeProvider>
  );
}
