import React from "react";
import Header from "../components/Header";
import RealEstateObjects from "../components/RealEstateObjects";
import AddRealEstateObject from "../dialogs/AddRealEstateObject";
import EditRealEstateObject from "../components/EditRealEstateObject";
import LoadingDialog from "../components/LoadingDialog";
import { Navigate } from "react-router";

const RealEstateObjectsPage = ({
  addItem,
  onAddItem,
  showLoading,
  showAddRealEstateObject,
  realEstateItems,
  deleteItem,
  onCloseDialog,
  onCloseEditDialog,
  tableUIItems,
  handleOpenInfo,
  handleOpenPhotos,
  handleEditItem,
  handleDeleteItem,
  queryObjects,
  regionSearchTerms,
  streetSearchTerms,
  isEditItem,
  item,
  onPageChanged,
  loadNextObjects,
  admin,
  onDeletePhotos,
  openViewPage,
}) => {
  return (
    <div>
      <Header
        onAddItem={onAddItem}
        queryObjects={queryObjects}
        regionSearchTerms={regionSearchTerms}
        streetSearchTerms={streetSearchTerms}
        admin={admin}
      />
      {showLoading && <LoadingDialog />}
      {showAddRealEstateObject && (
        <AddRealEstateObject
          onAddItem={addItem}
          onCloseDialog={onCloseDialog}
          regionSearchTerms={regionSearchTerms}
          streetSearchTerms={streetSearchTerms}
        />
      )}
      {isEditItem && (
        <EditRealEstateObject
          onEditItem={addItem}
          onCloseDialog={onCloseEditDialog}
          regionSearchTerms={regionSearchTerms}
          streetSearchTerms={streetSearchTerms}
          item={item}
          onDeletePhotos={onDeletePhotos}
        />
      )}
      {realEstateItems.length > 0 ? (
        <RealEstateObjects
          items={realEstateItems}
          onDeleteItem={deleteItem}
          tableUIItems={tableUIItems}
          handleOpenInfo={handleOpenInfo}
          handleOpenPhotos={handleOpenPhotos}
          handleEditItem={handleEditItem}
          handleDeleteItem={handleDeleteItem}
          onPageChanged={onPageChanged}
          loadNextObjects={loadNextObjects}
          admin={admin}
          openViewPage={openViewPage}
        />
      ) : (
        <div
          style={{
            alignContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          Наразі немає обєктів
        </div>
      )}
    </div>
  );
};

export default RealEstateObjectsPage;
