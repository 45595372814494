import React from "react";
import { Dialog } from "@material-ui/core";
import { useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Autocomplete, Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Collections, AddRounded } from "@mui/icons-material";

const AddClient = ({ onAddItem, onCloseDialog, regionSearchTerms }) => {
  const [dateFrom, setDateFrom] = useState("");
  const [rooms, setRooms] = useState(1);
  const [cost, setCost] = useState(6600);
  const [currency, setCurrency] = useState("");
  const [region, setRegion] = useState("");
  const [aboutClient, setAboutClient] = useState("");
  const [deadline, setDeadline] = useState(1);
  const [urgency, setUrgency] = useState("");
  const [personName, setPersonName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [info, setInfo] = useState("");
  const [displayDateFrom, setDisplayDateFrom] = useState(null);

  const onSave = (e) => {
    e.preventDefault();

    onAddItem({
      id: `${Math.floor(Math.random() * 1000000) + 1}-${new Date().getTime()}`,
      dateFrom,
      dateEdit: new Date(
        `${new Date().getUTCMonth() +
          1}/${new Date().getUTCDate()}/${new Date().getFullYear()}`
      ).getTime(),
      rooms,
      cost,
      currency,
      region,
      aboutClient,
      deadline,
      urgency,
      personName,
      phoneNumber,
      info,
    });
  };

  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <TextField
          id="name"
          label="Імя клієнта"
          variant="filled"
          type="text"
          value={personName}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          onChange={(e) => setPersonName(e.target.value)}
          fullWidth={true}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <TextField
          id="phoneNumber"
          label="Номер клієнта"
          variant="filled"
          type="text"
          value={phoneNumber}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          onChange={(e) => setPhoneNumber(e.target.value)}
          fullWidth={true}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={displayDateFrom}
            onChange={(newValue) => {
              setDisplayDateFrom(newValue);
              if (newValue === null) setDateFrom("");
              else
                setDateFrom(
                  `${newValue.getUTCMonth() + 1}/${newValue.getUTCDate() +
                    1}/${newValue.getFullYear()}`
                );
            }}
            placeholder="день/місяць/рік"
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "місяць/день/рік",
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") e.preventDefault();
                }}
                fullWidth={true}
                variant="filled"
                label="Дата заповнення"
              />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <TextField
          id="filled-basic"
          label="Кількість кімнат"
          variant="filled"
          type="number"
          value={rooms}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          onChange={(e) => setRooms(e.target.value)}
          fullWidth={true}
          error={rooms === ""}
          helperText={rooms === "" ? "Важливе поле" : " "}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <TextField
          id="filled-basic"
          label="Бажана ціна"
          variant="filled"
          type="number"
          value={cost}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          onChange={(e) => setCost(e.target.value)}
          fullWidth={true}
          error={cost === ""}
          helperText={cost === "" ? "Важливе поле" : " "}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <Autocomplete
          freeSolo
          options={["грн", "$"]}
          onInputChange={(e, newValue) => {
            setCurrency(newValue);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          fullWidth={true}
          renderInput={(params) => (
            <TextField
              {...params}
              type="text"
              variant="filled"
              label="Валюта"
            />
          )}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <Autocomplete
          freeSolo
          options={regionSearchTerms.map((option) => option)}
          onInputChange={(e, newValue) => {
            setRegion(newValue);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          fullWidth={true}
          renderInput={(params) => (
            <TextField
              {...params}
              type="text"
              variant="filled"
              error={region === ""}
              helperText={region === "" ? "Важливе поле" : " "}
              label="Район"
            />
          )}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <TextField
          id="name"
          label="Про клієнта"
          variant="filled"
          type="text"
          value={aboutClient}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          onChange={(e) => setAboutClient(e.target.value)}
          fullWidth={true}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <TextField
          id="filled-basic"
          label="Термін"
          variant="filled"
          type="number"
          value={deadline}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          onChange={(e) => setDeadline(e.target.value)}
          fullWidth={true}
          error={deadline === ""}
          helperText={deadline === "" ? "Важливе поле" : " "}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <Autocomplete
          freeSolo
          options={["Висока", "Середня", "Низька"]}
          onInputChange={(e, newValue) => {
            setUrgency(newValue);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          fullWidth={true}
          renderInput={(params) => (
            <TextField
              {...params}
              type="text"
              variant="filled"
              label="Терміновість"
            />
          )}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <TextField
          id="filled-basic"
          label="Додаткова інформація"
          variant="filled"
          type="text"
          value={info}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          onChange={(e) => setInfo(e.target.value)}
          fullWidth={true}
          multiline
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <Button variant="outlined" onClick={onCloseDialog} sx={{ width: 100 }}>
          <Typography variant="body2" fontSize={12} display="inline">
            Закрити
          </Typography>
        </Button>
        <Button
          variant="outlined"
          onClick={(e) => {
            if (region !== "" && deadline !== "" && rooms !== "" && cost !== "")
              onSave(e);
          }}
          sx={{ width: 100, marginLeft: 2 }}
        >
          <Typography variant="body2" fontSize={12} display="inline">
            Додати
          </Typography>
        </Button>
      </Box>
    </Dialog>
  );
};

export default AddClient;
