import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  root: {
    backgroundColor: "#2e1534",
  },
  typography: {
    padding: theme.spacing.unit * 3,
  },
});

const StyledTabs = withStyles((theme) => ({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > div": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "#0390fc",
    },
  },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "initial",
    color: "#000000",
    fontWeight: theme.typography.fontWeightLight,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing.unit * 1,
    "&:hover": {
      color: "#0390fc",
    },
    "&$selected": {
      color: "#0390fc",
    },
    "&:focus": {
      color: "#0390fc",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function ColorTabs({
  listObjects,
  listClients,
  listArchives,
  admin,
}) {
  const [value, setValue] = React.useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <StyledTabs value={value} onChange={handleChange}>
        <StyledTab
          // component={Link}
          // to="/"
          index={0}
          value="one"
          label="Обєкти"
          onClick={() => {
            listObjects(null);
          }}
        />
        <StyledTab
          // component={Link}
          // to="/Clients"
          index={1}
          value="two"
          label="Клієнти"
          onClick={() => {
            listClients(null);
          }}
        />
        {admin && (
          <StyledTab
            // component={Link}
            // to="/Archives"
            index={2}
            value="three"
            label="Архів"
            onClick={() => {
              listArchives(null);
            }}
          />
        )}
      </StyledTabs>
    </Box>
  );
}
