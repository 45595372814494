import { Dialog } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Autocomplete, Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const EditClient = ({ onEditItem, onCloseDialog, regionSearchTerms, item }) => {
  const [dateFrom, setDateFrom] = useState(item.dateFrom);
  const [rooms, setRooms] = useState(item.rooms);
  const [cost, setCost] = useState(item.cost);
  const [currency, setCurrency] = useState(item.currency);
  const [region, setRegion] = useState(item.region);
  const [aboutClient, setAboutClient] = useState(item.aboutClient);
  const [deadline, setDeadline] = useState(item.deadline);
  const [urgency, setUrgency] = useState(item.urgency);
  const [personName, setPersonName] = useState(item.personName);
  const [phoneNumber, setPhoneNumber] = useState(item.phoneNumber);
  const [info, setInfo] = useState(item.info);
  const [displayDateEdit, setDisplayDateEdit] = useState(new Date());
  const [displayDateFrom, setDisplayDateFrom] = useState(null);

  const onSave = (e) => {
    e.preventDefault();
    item = {
      id: item.id,
      dateFrom: dateFrom,
      dateEdit: new Date(
        `${displayDateEdit.getUTCMonth() +
          1}/${displayDateEdit.getUTCDate()}/${displayDateEdit.getFullYear()}`
      ).getTime(),
      rooms: rooms,
      cost: cost,
      currency: currency,
      region: region,
      aboutClient: aboutClient,
      deadline: deadline,
      urgency: urgency,
      personName: personName,
      phoneNumber: phoneNumber,
      info: info,
    };
    onEditItem(item);
    onCloseDialog();
  };

  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <TextField
          id="name"
          label="Імя клієнта"
          variant="filled"
          type="text"
          defaultValue={personName}
          value={personName}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          onChange={(e) => setPersonName(e.target.value)}
          fullWidth={true}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <TextField
          id="phoneNumber"
          label="Номер клієнта"
          variant="filled"
          type="text"
          defaultValue={phoneNumber}
          value={phoneNumber}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          onChange={(e) => setPhoneNumber(e.target.value)}
          fullWidth={true}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={displayDateFrom}
            onChange={(newValue) => {
              setDisplayDateFrom(newValue);
              if (newValue === null) setDateFrom("");
              else
                setDateFrom(
                  `${newValue.getUTCMonth() + 1}/${newValue.getUTCDate() +
                    1}/${newValue.getFullYear()}`
                );
            }}
            placeholder="день/місяць/рік"
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "місяць/день/рік",
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") e.preventDefault();
                }}
                fullWidth={true}
                variant="filled"
                label="Дата заповнення"
              />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={displayDateEdit}
            onChange={(newValue) => {
              setDisplayDateEdit(newValue);
              // if (newValue === null) setDateEdit("");
              // else
              //   setDateEdit(
              //     `${newValue.getUTCMonth() +
              //       1}/${newValue.getUTCDate()}/${newValue.getFullYear()}`
              //   );
            }}
            placeholder="день/місяць/рік"
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "місяць/день/рік",
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") e.preventDefault();
                }}
                fullWidth={true}
                variant="filled"
                label="Дата редагування"
              />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <TextField
          id="filled-basic"
          label="Кількість кімнат"
          variant="filled"
          type="number"
          defaultValue={rooms}
          value={rooms}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          onChange={(e) => setRooms(e.target.value)}
          fullWidth={true}
          error={rooms === ""}
          helperText={rooms === "" ? "Важливе поле" : " "}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <TextField
          id="filled-basic"
          label="Бажана ціна"
          variant="filled"
          type="number"
          defaultValue={cost}
          value={cost}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          onChange={(e) => setCost(e.target.value)}
          fullWidth={true}
          error={cost === ""}
          helperText={cost === "" ? "Важливе поле" : " "}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <Autocomplete
          freeSolo
          options={["грн", "$"]}
          inputValue={currency}
          onInputChange={(e, newValue) => {
            setCurrency(newValue);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          fullWidth={true}
          renderInput={(params) => (
            <TextField
              {...params}
              type="text"
              variant="filled"
              label="Валюта"
            />
          )}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <Autocomplete
          freeSolo
          inputValue={region}
          options={regionSearchTerms.map((option) => option)}
          onInputChange={(e, newValue) => {
            setRegion(newValue);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          fullWidth={true}
          renderInput={(params) => (
            <TextField
              {...params}
              type="text"
              variant="filled"
              error={region === ""}
              helperText={region === "" ? "Важливе поле" : " "}
              label="Район"
            />
          )}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <TextField
          id="name"
          label="Про клієнта"
          variant="filled"
          type="text"
          defaultValue={aboutClient}
          value={aboutClient}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          onChange={(e) => setAboutClient(e.target.value)}
          fullWidth={true}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <TextField
          id="filled-basic"
          label="Термін"
          variant="filled"
          type="number"
          defaultValue={deadline}
          value={deadline}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          onChange={(e) => setDeadline(e.target.value)}
          fullWidth={true}
          error={deadline === ""}
          helperText={deadline === "" ? "Важливе поле" : " "}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <Autocomplete
          freeSolo
          options={["Висока", "Середня", "Низька"]}
          inputValue={urgency}
          onInputChange={(e, newValue) => {
            setUrgency(newValue);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          fullWidth={true}
          renderInput={(params) => (
            <TextField
              {...params}
              type="text"
              variant="filled"
              label="Терміновість"
            />
          )}
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <TextField
          id="filled-basic"
          label="Докладна інформація"
          variant="filled"
          type="text"
          defaultValue={info}
          value={info}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          onChange={(e) => setInfo(e.target.value)}
          fullWidth={true}
          multiline
        />
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="center"
        m={1}
      >
        <Button variant="outlined" onClick={onCloseDialog} sx={{ width: 100 }}>
          <Typography variant="body2" fontSize={12} display="inline">
            Закрити
          </Typography>
        </Button>
        <Button
          variant="outlined"
          onClick={(e) => {
            if (region !== "" && deadline !== "" && rooms !== "" && cost !== "")
              onSave(e);
          }}
          sx={{ width: 100, marginLeft: 2 }}
        >
          <Typography variant="body2" fontSize={12} display="inline">
            Змінити
          </Typography>
        </Button>
      </Box>
    </Dialog>
  );
};

export default EditClient;
