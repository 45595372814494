import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Modal from "@mui/material/Modal";
import { Box, Button, Dialog, IconButton, Stack } from "@mui/material";
import { FileDownloadOutlined, Close, Delete } from "@mui/icons-material";
import { height, width } from "@mui/system";
import { rgbToHex } from "@material-ui/core";

export default function StandardImageList({
  openPhotos,
  handleClosePhotos,
  photos,
  onDeletePhoto,
  admin,
}) {
  const deletePhoto = (item) => {
    onDeletePhoto(item);
  };

  const download = (item) => {
    fetch(item.url, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${item.name}`); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const style = {
    position: "absolute",
    top: "15%",
    left: "30%",
    height: "fit-screen",
    width: "fit-screen",
  };

  return (
    <div>
      {/* {console.log(photos)} */}
      <Dialog
        open={openPhotos}
        onClose={() => {
          handleClosePhotos();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box component="form" display="flex" justifyContent="center">
          <ImageList
            sx={{ width: "auto", height: "auto" }}
            cols={1}
            rowHeight="auto"
          >
            {photos.length > 0 ? (
              photos.map((item) => (
                <ImageListItem key={item.name}>
                  <Box display="flex" justifyContent="center">
                    <Stack spacing={1} direction="row">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          download(item);
                        }}
                      >
                        <FileDownloadOutlined />
                      </IconButton>
                      {admin && (
                        <IconButton
                          color="primary"
                          onClick={() => {
                            deletePhoto(item);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      )}
                    </Stack>
                  </Box>
                  <img
                    src={item.url}
                    srcSet={item.url}
                    loading="lazy"
                    key={item.key}
                    crossOrigin="anonymous"
                  />
                </ImageListItem>
              ))
            ) : (
              <></>
            )}
          </ImageList>
        </Box>
      </Dialog>
    </div>
  );
}
