import ViewObjectPage from "./pages/ViewObjectPage";
import React from "react";
import { useState, useEffect } from "react";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { DynamoDBClient, GetItemCommand } from "@aws-sdk/client-dynamodb";
import { ListObjectsCommand } from "@aws-sdk/client-s3";
import { S3Client } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import LoadingDialog from "./components/LoadingDialog";
import { Outlet, useParams } from "react-router-dom";
import App from "./App";

const TABLE_NAME_OBJECTS = "objects_v3";
const TABLE_NAME_CLIENTS = "clients_v2";
const TABLE_NAME_ARCHIVES = "archives_v2";
const REGION = "eu-central-1";
const IDENTITY_POOL_ID = "eu-central-1:713c28fd-d5fa-4e35-a9de-b89640b12b65";
const OBJECTS_PROJECTION =
  "id, cost, currency, dateFrom, dateTo, floor, furniture, heating, info, personName, phoneNumber, region, rooms, state, street, technology";
const CLIENTS_PROJECTION =
  "id, cost, aboutClient, currency, dateFrom, deadline, info, personName, phoneNumber, region, rooms, urgency";
const SCAN_LIMIT = 100;
const S3_PHOTOS_BUCKET = "realtor-photos";

var ddbClient;
var s3Client;

var credentials = fromCognitoIdentityPool({
  client: new CognitoIdentityClient({ region: REGION }),
  identityPoolId: IDENTITY_POOL_ID,
});

ddbClient = new DynamoDBClient({
  region: REGION,
  credentials: credentials,
});

s3Client = new S3Client({
  region: REGION,
  credentials: credentials,
});

function AppClient() {
  const [viewPageValues, setViewPageValues] = useState({});
  const [showLoading, setShowLoading] = useState(false);
  const params = useParams();

  const getPhotos = async (photosId) => {
    //...
    //after loaded photos
    const albumPhotosKey = encodeURIComponent(photosId) + "/";
    try {
      var photos = [];

      const data = await s3Client.send(
        new ListObjectsCommand({
          Prefix: albumPhotosKey,
          Bucket: S3_PHOTOS_BUCKET,
        })
      );

      const href = "https://s3." + REGION + ".amazonaws.com/";
      const bucketUrl = href + S3_PHOTOS_BUCKET + "/";

      if (data.Contents === undefined) return;

      data.Contents.map((photo) => {
        //console.log(bucketUrl + encodeURIComponent(photo.Key));
        photos.push({
          url: bucketUrl + encodeURIComponent(photo.Key),
          name: photo.Key.split("/").pop(),
          id: photosId,
          key: photo.Key,
        });
      });

      //console.log(photos);
      return photos;
    } catch (err) {
      alert(err);
      console.error(err);
    }
  };

  const loadObjectInfo = async () => {
    setShowLoading(true);
    if (ddbClient != null) {
      try {
        const windowLocation = window.location.href
          .split("/")
          .pop()
          .split("?")
          .pop();
        console.log(
          window.location.href
            .split("/")
            .pop()
            .split("?")
            .pop()
        );
        var data = await ddbClient.send(
          new GetItemCommand({
            TableName: TABLE_NAME_OBJECTS,
            Key: {
              id: {
                S: params.object_id,
              },
            },
          })
        );
        if (data.Item == undefined) {
          data = await ddbClient.send(
            new GetItemCommand({
              TableName: TABLE_NAME_ARCHIVES,
              Key: {
                id: {
                  S: params.object_id,
                },
              },
            })
          );
        }
        const item = {
          cost: data.Item.cost.N,
          currency: data.Item.currency.S,
          info: data.Item.info.S,
          region: data.Item.region.S,
          rooms: data.Item.rooms.N,
          street: data.Item.street.S,
          floor: data.Item.floor.N,
        };
        const photos = await getPhotos(params.object_id);
        setViewPageValues({ ...item, Photos: photos });
      } catch (err) {
        alert(err);
        console.error(err);
      }
    } else {
      console.log("ddbClient == null!");
    }
    setShowLoading(false);
  };

  useEffect(() => {
    loadObjectInfo();
  }, []);

  return (
    <div className="App">
      {<ViewObjectPage viewPageValues={viewPageValues} />}
      {showLoading && <LoadingDialog />}
      <Outlet />
    </div>
  );
}

export default AppClient;
