import React from "react";
import EditArchive from "../components/EditArchive";
import ArchivesTable from "../tables/ArchivesTable";
import LoadingDialog from "../components/LoadingDialog";
import HeaderArchives from "../components/HeaderArchives";

const ArchivesPage = ({
  release,
  items,
  deleteItem,
  openArchiveInfo,
  handleOpenPhotos,
  returnItem,
  loadNextArchives,
  openViewPage,
  isEditItem,
  editArchiveItem,
  onCloseEditDialog,
  regionSearchTerms,
  streetSearchTerms,
  onEditItem,
  showLoading,
  handleEditItem,
  queryObjects,
}) => {
  return (
    <div>
      <HeaderArchives
        queryObjects={queryObjects}
        regionSearchTerms={regionSearchTerms}
        streetSearchTerms={streetSearchTerms}
      />
      {showLoading && <LoadingDialog />}
      {items.length > 0 ? (
        <ArchivesTable
          items={items}
          deleteItem={deleteItem}
          openArchiveInfo={openArchiveInfo}
          handleOpenPhotos={handleOpenPhotos}
          returnItem={returnItem}
          loadNextObjects={loadNextArchives}
          openViewPage={openViewPage}
          handleEditItem={handleEditItem}
        />
      ) : (
        <div
          id="No_clients"
          style={{
            alignContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          Немає архівів
        </div>
      )}
      {isEditItem && (
        <EditArchive
          onEditItem={onEditItem}
          onCloseDialog={onCloseEditDialog}
          regionSearchTerms={regionSearchTerms}
          streetSearchTerms={streetSearchTerms}
          item={editArchiveItem}
        />
      )}
    </div>
  );
};

export default ArchivesPage;
