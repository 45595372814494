import React from "react";
import { CircularProgress, Dialog } from "@material-ui/core";

const LoadingDialog = () => {
  return (
    <Dialog
      className="loading-dialog"
      open={true}
      PaperProps={{
        style: {
          padding: "10px 10px 10px 10px",
        },
      }}
    >
      <CircularProgress />
    </Dialog>
  );
};

export default LoadingDialog;
