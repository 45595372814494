import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material";
import AppRealtor from "./AppRealtor";
import AppClient from "./AppClient";

const PRODUCTION = !window.location.href.includes("localhost");

const TABLE_NAME_OBJECTS = "objects_v3";
const TABLE_NAME_CLIENTS = "clients_v2";
const TABLE_NAME_ARCHIVES = "archives_v2";
const REGION = "eu-central-1";
const IDENTITY_POOL_ID = "eu-central-1:713c28fd-d5fa-4e35-a9de-b89640b12b65";
const OBJECTS_PROJECTION =
  "id, cost, currency, dateFrom, dateTo, floor, furniture, heating, info, personName, phoneNumber, region, rooms, state, street, technology";
const CLIENTS_PROJECTION =
  "id, cost, aboutClient, currency, dateFrom, deadline, info, personName, phoneNumber, region, rooms, urgency";
const SCAN_LIMIT = 100;
const S3_PHOTOS_BUCKET = "realtor-photos";

function App() {
  return (
    <div
      style={{
        //overflowX: "hidden",
        overflow: "hidden",
        minHeight: "100vh",
        //background: "linear-gradient(to top, #ffffff 90%, #d6d6d6)",
      }}
    >
      <StyledEngineProvider injectFirst>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<AppRealtor />} />
            <Route path="/Clients" element={<AppRealtor />} />
            <Route path="/Archives" element={<AppRealtor />} />
            <Route path="/View">
              <Route index element={<></>}></Route>
              <Route path=":object_id" element={<AppClient />}></Route>
            </Route>
            <Route path="*" element={<div>Невідома сторінка</div>}></Route>
          </Routes>
          {/* {window.location.href.includes("id_token") && <Navigate to="/" />} */}
        </BrowserRouter>
      </StyledEngineProvider>
    </div>
  );
}
export default App;
