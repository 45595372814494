import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Container, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const BeforeDeleteObject = ({
  openDialog,
  id,
  deleteObject,
  handleCloseDialog,
}) => {
  const [displayDateFrom, setDisplayDateFrom] = useState(new Date());
  const [dateFrom, setDateFrom] = useState(
    `${new Date().getUTCMonth() +
      1}/${new Date().getUTCDate()}/${new Date().getFullYear()}`
  );
  const handleDeleteItem = () => {
    handleCloseDialog();
    deleteObject(id, { displayDateFrom, dateFrom });
  };

  return (
    <Modal
      open={openDialog}
      display="flex"
      justifyContent="center"
      onClose={() => {
        handleCloseDialog();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        style={{
          display: "flex",
          backgroundColor: "#FFFFFF",
          width: 200,
          height: "auto",
          paddingBottom: "10px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          alignItems: "center",
          //justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <Box m="auto" justifyContent="center" display="flex">
          <Typography
            id="modal-modal-title"
            variant="body2"
            component="h2"
            fontWeight="Light"
            textAlign="center"
            paddingTop={1}
          >
            Додати обєкт в архів?
          </Typography>
        </Box>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          display="flex"
          justifyContent="center"
          m={1}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={displayDateFrom}
              onChange={(newValue) => {
                setDisplayDateFrom(newValue);
                if (newValue === null) setDateFrom("");
                else
                  setDateFrom(
                    `${newValue.getUTCMonth() +
                      1}/${newValue.getUTCDate()}/${newValue.getFullYear()}`
                  );
              }}
              placeholder="день/місяць/рік"
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "місяць/день/рік",
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") e.preventDefault();
                  }}
                  fullWidth={true}
                  variant="filled"
                  label="Термін архіву"
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Stack direction="row" spacing={2} width="100%" justifyContent="center">
          <Button
            variant="outlined"
            onClick={handleCloseDialog}
            sx={{ fontWeight: 300 }}
          >
            Ні
          </Button>
          <Button
            variant="outlined"
            onClick={handleDeleteItem}
            sx={{ fontWeight: 300 }}
          >
            Так
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default BeforeDeleteObject;

BeforeDeleteObject.defaultProps = {
  infoText: "Немає додаткових даних",
};
