import React from "react";
import DataTable from "../tables/DataTable";
import RealEstateItem from "./RealEstateItem";

const RealEstateObjects = ({
  items,
  onDeleteItem,
  handleOpenInfo,
  handleOpenPhotos,
  handleEditItem,
  handleDeleteItem,
  onPageChanged,
  loadNextObjects,
  admin,
  openViewPage,
}) => {
  return (
    <>
      <DataTable
        items={items}
        handleOpenInfo={handleOpenInfo}
        handleOpenPhotos={handleOpenPhotos}
        handleDeleteItem={handleDeleteItem}
        handleEditItem={handleEditItem}
        onPageChanged={onPageChanged}
        loadNextObjects={loadNextObjects}
        admin={admin}
        openViewPage={openViewPage}
      />
    </>
  );
};

export default RealEstateObjects;
