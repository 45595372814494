import * as React from "react";
import { DataGrid, ukUA } from "@mui/x-data-grid";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  Info,
  Delete,
  Edit,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import Modal from "@mui/material/Modal";
import BuildingInfo from "../dialogs/Info";
import { IconButton, Stack } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "../styles.css";

export default function ClientsDataTable({
  items,
  handleOpenInfo,
  handleDeleteItem,
  handleEditItem,
  loadNextClients,
  admin,
}) {
  const MAX_ROW_LENGTH = 50;
  const [loadedRows, setLoadedRows] = React.useState([]);
  const [visible, setVisible] = React.useState(""); //holds row id's
  var pageRowsCount = 0;
  var date = new Date();

  const theme = createTheme(ukUA);

  const getDate = (time) => {
    date.setTime(time);
    return `${date.getUTCMonth() + 1}/${date.getUTCDate() +
      1}/${date.getFullYear()}`;
  };

  var columns = [
    { field: "dateFrom", headerName: "Додано", width: 90 },
    {
      field: "dateEdit",
      headerName: "Редаговано",
      width: 90,
      renderCell: (cellValues) => {
        return <>{getDate(cellValues.row.dateEdit)}</>;
      },
    },
    {
      field: "rooms",
      headerName: "Кімнат",
      width: 90,
      type: "number",
    },
    {
      field: "cost",
      headerName: "Ціна(грн)",
      width: 130,
      type: "number",
    },
    { field: "currency", headerName: "Валюта", width: 130 },
    { field: "region", headerName: "Район", width: 130 },
    {
      field: "aboutClient",
      headerName: "Про клієнта",
      width: 130,
    },
    {
      field: "deadline",
      headerName: "Термін",
      width: 130,
      type: "number",
    },
    {
      field: "urgency",
      headerName: "Терміновість",
      width: 130,
    },
    { field: "personName", headerName: "Ім'я", width: 130 },
    {
      field: "phoneNumber",
      headerName: "Номер",
      width: 130,
      renderCell: (cellValues) => {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            {visible === cellValues.row.id ? (
              <>
                {cellValues.formattedValue}
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    setVisible("");
                  }}
                >
                  <VisibilityOff style={{ width: 15, height: 15 }} />
                </IconButton>
              </>
            ) : (
              <>
                {`******${cellValues.formattedValue.substring(
                  cellValues.formattedValue.length - 3,
                  cellValues.formattedValue.length
                )}`}
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    setVisible(cellValues.row.id);
                  }}
                >
                  <Visibility style={{ width: 15, height: 15 }} />
                </IconButton>
              </>
            )}
          </Stack>
        );
      },
    },
  ];

  if (admin) {
    columns.push({
      field: "info",
      type: "actions",
      width: 200,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Info />}
          onClick={() => handleOpenInfo(params.id)}
          label="Info"
        />,
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          onClick={() => handleEditItem(params.id)}
        />,
        <GridActionsCellItem
          icon={<Delete />}
          label="Delete"
          onClick={() => handleDeleteItem(params.id)}
        />,
      ],
    });
  } else {
    columns.push({
      field: "info",
      type: "actions",
      width: 200,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Info />}
          onClick={() => handleOpenInfo(params.id)}
          label="Info"
        />,
      ],
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          height: window.innerHeight - 110,
          width: "100%",
          textAlign: "center",
        }}
      >
        <DataGrid
          sx={{
            ".MuiDataGrid-cellContent": {
              overflowWrap: "break-word",
            },
          }}
          disableSelectionOnClick
          disableColumnFilter={true}
          hideFooterSelectedRowCount
          getRowHeight={() => "auto"}
          rows={items}
          columns={columns}
          pageSize={MAX_ROW_LENGTH}
          rowsPerPageOptions={[MAX_ROW_LENGTH]}
          showCellRightBorder
          showColumnRightBorder
          onPageChange={(pageNumber, details) => {
            if (items.length <= MAX_ROW_LENGTH * (pageNumber + 1)) {
              console.log("last page reached");
              loadNextClients();
            }
            pageRowsCount = items.length - MAX_ROW_LENGTH * pageNumber;
          }}
        />
      </div>
    </ThemeProvider>
  );
}
